import React, { useState, useEffect } from 'react';
import { TextField, Paper, Typography, Box, MenuItem, Select, InputLabel, Button, Modal, Checkbox, MenuList, DialogContent, DialogTitle, DialogActions, Dialog } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TimerIcon from '@mui/icons-material/Timer';
import { useDispatch, useSelector } from 'react-redux';
import { getSlotsAction } from '../../store/slices/getSlots';
import { getBookingAction, getPaymentAction } from '../../store/actions';
import { getProfileDetailsAction } from '../../store/slices/getprofiledetails';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { postBonusSlotBookingAction } from '../../store/slices/bonusSlotBooking';
import { clearPostBonusSlotBookingAction } from '../../store/slices/bonusSlotBooking';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {useMediaQuery} from '@mui/material';

function Bookingpage() {
  const { successData: BookingsData, error: BookingError, errorInfo: BookingErrorInfo } = useSelector((state) => state.bookingReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { successData, loading, error } = useSelector((state) => state.getSlotsReducer);
  const { successData: profileData } = useSelector((state) => state.getProfileDetailsReducer);
  const { successData: bonusSlotBookingData, error: bonusSlotBookingError, errorInfo: bonusSlotBookingErrorInfo } = useSelector((state) => state.bonusSlotBookingReducer);
  const { successData: makePayment, error: paymentError, errorInfo: paymentErrorInfo } = useSelector((state) => state.paymentReducer);
  const location = useLocation();

  const [selectedSport, setSelectedSport] = useState(location.state?.selectedSport || '');
  const today = new Date();
  const formattedToday = today.toISOString().split('T')[0];
  const [date, setDate] = useState(formattedToday);
  const [selectedTime, setSelectedTime] = useState({});
  const [duration, setDuration] = useState(60);
  const [cartItems, setCartItems] = useState([]);
  const [hasBeenAdded, setHasBeenAdded] = useState(false);
  const [enhancedSlots, setEnhancedSlots] = useState([]);
  const [isBookingForOthers, setIsBookingForOthers] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [otherName, setOtherName] = useState('');
  const [otherMobile, setOtherMobile] = useState('');
  const [hasBonusSlot, setHasBonusSlot] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isBookingInProgress, setIsBookingInProgress] = useState(false);

  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');
 
  console.log("BookingsData", BookingsData)

  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const sportsOptions = [
    { value: 'cricket', label: 'Cricket', icon: <SportsCricketIcon /> },
    { value: 'football', label: 'Football', icon: <SportsSoccerIcon /> },
  ];



  const handleDateDropdownClick = () => {
    setOpenDropdown(openDropdown === 'date' ? null : 'date');
  };

  const handleSlotDropdownClick = () => {
    setOpenDropdown(openDropdown === 'slot' ? null : 'slot');
  };

  const handleCheckboxChange = (e) => {
    setIsBookingForOthers(e.target.checked);
    setOpenModal(e.target.checked);
  };


  const handleSave = () => {
    setOpenModal(false);
    const updatedCartItems = cartItems.map(item => ({
      ...item,
      otherName: otherName || item.otherName,
      otherMobile: otherMobile || item.otherMobile
    }));

    setCartItems(updatedCartItems);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setIsBookingForOthers(false);
    setOtherName('');
    setOtherMobile('');
  };

  const clearCart = () => {
    setCartItems([]);
    setSelectedTime({});
    setHasBeenAdded(false);
    setShowBookingDetails(false);

  };


  // const handleTimeChange = (event) => {
  //   const selectedStartTime = event.target.value;


  //   const matchedStartTime = enhancedSlots.find(slot => slot.start_time === selectedStartTime);
  //   if (matchedStartTime) {
  //     setSelectedTime(matchedStartTime);
  //     setDuration(matchedStartTime.duration);
  //   }
  // };



  const handleDateChange = (e) => {
    setDate(e.target.value);
    setSelectedTime({});
  };

  const handleAddToCart = async () => {
    if (isBookingInProgress) return;

    setIsBookingInProgress(true);
    setIsLoading(true);

    const isItemAlreadyInCart = cartItems.some(
      (item) => item.date === date && item.selectedTime.start_time === selectedTime.start_time
    );

    if (!isItemAlreadyInCart && date && selectedTime.start_time) {
      const surgeAmount = selectedTime.surge_amount ? parseInt(selectedTime.surge_amount) : 0;
      const basePrice = parseInt(selectedTime.base_price);
      const TotalPize = basePrice + surgeAmount;

      const payload = {
        user_id: userDetails.id,
        daily_slot_id: selectedTime.dailyslot_id,
        slot_id: selectedTime.slot_id,
        booking_for: isBookingForOthers ? "friend" : "self",
        friend_name: otherName,
        friend_mobile_no: otherMobile,
        booking_type: selectedSport,
      };

      try {
        const result = await dispatch(getBookingAction(payload));

        if (result.type === 'BOOKING/POST/fulfilled') {
          setShowBookingDetails(true);
          const newCartItem = {
            selectedTime,
            duration,
            TotalPize,
            date,
            sport: selectedSport,
            bookedFor: isBookingForOthers ? true : false,
            otherName: isBookingForOthers ? otherName : '',
            otherMobile: isBookingForOthers ? otherMobile : ''
          };

          setCartItems([...cartItems, newCartItem]);
          setHasBeenAdded(true);

          if (profileData && profileData.data.is_bonus_slot) {
            setHasBonusSlot(true);
          } else {
            setHasBonusSlot(false);
          }
        } else {
          toast.error("Booking failed. Please try again.");
        }
      } catch (error) {
        console.error("Error in booking:", error);
        toast.error("An error occurred while booking. Please try again.");
      } finally {
        setIsBookingInProgress(false);
        setIsLoading(false);
      }
    } else {
      toast.info("This slot is already in your cart or invalid selection.");
      setIsBookingInProgress(false);
    }
  };




  useEffect(() => {
    if (date || selectedTime) {
      setHasBeenAdded(false);
    }
  }, [date, selectedTime]);

  useEffect(() => {
    dispatch(getSlotsAction());
  }, [dispatch]);

  // if bonus slot booking sucess show success modal
  useEffect(() => {
    if (bonusSlotBookingData) {
      setShowSuccessModal(true);
    }
  }, [bonusSlotBookingData]);

  useEffect(() => {
    if (makePayment) {
      console.log(makePayment);
      window.location.href = makePayment.redirectUrl;
    }
    if(paymentError){
      toast.error(paymentErrorInfo)
    }
    
  }, [makePayment,paymentError,paymentErrorInfo]);




  const handleProceedToPayment = () => {
    if (cartItems.length > 0) {
      const totalPrize = cartItems.reduce((total, item) => total + item.TotalPize, 0);
      const payload = {
        user_id: userDetails.id,
        booking_id: BookingsData.booking_id,
        amount: totalPrize,
        payment_method: "Razorpay",
        payment_type: "slot",
        mobileNumber: userDetails.mobile_no,
      };
      dispatch(getPaymentAction(payload));
    }
    else {
      toast.error("Your cart is empty. Please add items before proceeding to payment.");
    }
  };


  const generateNext10Days = () => {
    const dates = [];
    for (let i = 0; i < 10; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      dates.push({
        value: date.toISOString().split('T')[0],
        label: date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        }).replace(/ /g, '-')
      });
    }
    return dates;
  };
  const availableDates = generateNext10Days();

  useEffect(() => {
    if (successData && successData.data && successData.data.dailyslots && successData.data.dailyslots.length > 0) {
      const enhancedSlotsArray = successData.data.dailyslots
        .filter(dailyslot => dailyslot.date === date)
        .map((dailyslot) => {
          const matchedSlot = successData.data.slots.find(slot => slot.slot_id === dailyslot.slot_id);
          if (matchedSlot) {
            return {
              ...matchedSlot,
              dailyslot_id: dailyslot.dailyslot_id,
              surge_amount: dailyslot.surge_amount,
            };
          }
          return null;
        })
        .filter(Boolean);
     
         // Sort the enhancedSlotsArray by start_time
      enhancedSlotsArray.sort((a, b) => {
        const timeA = a.start_time.split(':').map(Number);
        const timeB = b.start_time.split(':').map(Number);
        return timeA[0] - timeB[0] || timeA[1] - timeB[1];
      });

      setEnhancedSlots(enhancedSlotsArray);
      
    } else {
      setEnhancedSlots([]);
    }
  }, [successData, date]);


  const isFormValid = date && selectedTime.start_time && duration;

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(' ')[0].slice(0, 5);
  };

  const isSlotBeforeCurrentTime = (slotTime) => {
    const currentTime = getCurrentTime();
    return date === new Date().toISOString().split('T')[0] && slotTime < currentTime;
  };

  useEffect(() => {
    dispatch(getProfileDetailsAction({
      endPoint: `users/profile?user_id=${userDetails.id}`,
    }));
  }, [dispatch, userDetails.id]);





  const handleUseBonusSlot = () => {
    dispatch(postBonusSlotBookingAction({
      user_id: userDetails.id,
      daily_slot_id: selectedTime.dailyslot_id,
      slot_id: selectedTime.slot_id,
      booking_type: selectedSport

    }));
  };

  const handleCloseModal = () => {
    dispatch(clearPostBonusSlotBookingAction())
    setShowSuccessModal(false);
    navigate('/');
  };

  const handleBlur = (event) => {
    const relatedTarget = event.relatedTarget;
    if (!relatedTarget || (!relatedTarget.closest('.date-dropdown') && !relatedTarget.closest('.slot-dropdown'))) {
      setOpenDropdown(null);
    }
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: { xs: '7rem', sm: '7rem' }, padding: { xs: '0 1rem', sm: '0' }, marginBottom: { xs: '40px', sm: '' } }}>
 {(!showBookingDetails || !isMobile) && (
        <Paper elevation={3} sx={{ padding: '1.3rem', marginLeft: { xs: '0px', sm: '2rem' }, backgroundColor: '#fff', marginBottom: { xs: '2rem', sm: '0' } }}>         
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" sx={{ marginBottom: { xs: '', sm: '2rem' } }}>Sports</Typography>
          <InputLabel id="sports-label" />
          <Select
            labelId="sports-label"
            value={selectedSport}
            onChange={(e) => setSelectedSport(e.target.value)}
            sx={{
              width: { xs: '100%', sm: '42%' },
              marginLeft: { xs: '20%', sm: '20%' },
              marginBottom: { xs: '2px', sm: '1rem' },
            }}
          >
            {sportsOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.icon} {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ marginTop: { xs: '1.5rem' } }}>Date</Typography>
          <Box className="date-dropdown" sx={{ position: 'relative', marginLeft: { xs: '24%', sm: '23%' }, marginTop: '1rem' }} >
            <TextField
              value={date ? new Date(date).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              }).replace(/ /g, '-') : ''}
              onClick={handleDateDropdownClick}
              placeholder="Select a date"
              onBlur={handleBlur}
              sx={{
                backgroundColor: '#fff',
                width: {
                  sm: '100%', xs: '100%',
                }
              }}
              InputProps={{
                readOnly: true,
                disabled: hasBeenAdded
              }}
            />
            {openDropdown === 'date' && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  marginTop: '0.5rem',
                  padding: '0.5rem',
                  width: '120%',
                  maxHeight: '150px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#31303b',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '8px',
                  },
                }}
              >
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                  {availableDates.map((dateOption) => (
                    <MenuItem
                      onMouseDown={() => {
                        setDate(dateOption.value);
                        setOpenDropdown(null);
                      }}
                      key={dateOption.value}
                      sx={{
                        padding: '10px 15px',
                        backgroundColor: date === dateOption.value ? '#31303b' : '#fff',
                        color: date === dateOption.value ? '#fff' : '#000',
                        borderBottom: '1px solid #f0f0f0',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: 'grey',
                          borderRadius: '10px',
                        },
                      }}
                    >
                      {dateOption.label}
                    </MenuItem>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ marginTop: { xs: '2rem', sm: '2rem' }, marginBottom: { xs: '1rem', sm: '2rem' } }}>Start Time</Typography>
          <Box className="slot-dropdown" sx={{ position: 'relative', marginLeft: { xs: '14%', sm: '14%' }, marginBottom: { xs: '', sm: '1rem' }, marginTop: { xs: '', sm: '2rem' } }}>
            <TextField
              value={selectedTime.start_time ? convertTo12HourFormat(selectedTime.start_time) : ''}
              onClick={handleSlotDropdownClick}
              placeholder="Select a slot"
              onBlur={handleBlur}
              sx={{
                backgroundColor: '#fff',
                width: {
                  sm: '100%', xs: '100%',
                }
              }}
              InputProps={{
                readOnly: true,
                disabled: hasBeenAdded
              }}
            />
            {openDropdown === 'slot' && (
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 10,
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                  marginTop: '0.5rem',
                  padding: '0.5rem',
                  width: '100%',
                  maxHeight: '150px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#31303b',
                    borderRadius: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '8px',
                  },
                }}
              >
                {enhancedSlots.length > 0 && enhancedSlots.filter(slot => slot.is_active && !isSlotBeforeCurrentTime(slot.start_time)).length > 0 ? (
                  <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                    {enhancedSlots
                      .filter(slot => slot.is_active && !isSlotBeforeCurrentTime(slot.start_time))
                      .map((slot) => (
                        <MenuList
                          sx={{
                            backgroundColor: '#f8f8f8',
                            padding: '0',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <MenuItem
                            onMouseDown={() => {
                              setSelectedTime(slot);
                              setOpenDropdown(false);
                            }}
                            key={slot.dailyslot_id}
                            onClick={() => {
                              setSelectedTime(slot);
                              setOpenDropdown(false);
                            }}
                            sx={{
                              padding: '10px 15px',
                              backgroundColor: selectedTime.start_time === slot.start_time ? '#31303b' : '#fff',
                              color: selectedTime.start_time === slot.start_time ? '#fff' : '#000',
                              borderBottom: '1px solid #f0f0f0',
                              cursor: 'pointer',
                              borderRadius: '10px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              '&:hover': {
                                backgroundColor: 'grey',
                                borderRadius: '10px',

                              },
                            }}
                          >
                            {convertTo12HourFormat(slot.start_time)}
                          </MenuItem>
                        </MenuList>
                      ))}
                  </Box>
                ) : (
                  <MenuItem value="" >   
                   {date === formattedToday ? "Slots filled for today." : "All Slots are Booked for This Date"}
                  </MenuItem>
                )}
              </Box>
            )}



          </Box>
        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
          <Typography variant="h6">Duration</Typography>
          <Typography variant="body1" sx={{ minWidth: '80px', textAlign: 'center', marginLeft: { sm: '50px', xs: '15px' } }}>1 Hour</Typography>
          {/* <RemoveCircleIcon onClick={handleDecrease} sx={{ color: 'red', marginLeft: '5rem', fontSize: '2rem' }} />
          <Typography variant="body1" sx={{ minWidth: '80px', textAlign: 'center' }}> {`${Math.floor((duration) / 60)}hr`} </Typography>
           <AddCircleIcon onClick={handleIncrease} sx={{ color: 'green', fontSize: '2rem' }}  /> */}
        </Box>

        <Checkbox
          checked={isBookingForOthers}
          onChange={handleCheckboxChange}
          color="primary"
          disabled={hasBeenAdded}
        />
        <Typography display="inline">Booking for others</Typography>
        <Button onClick={handleAddToCart}
          disabled={hasBeenAdded || !isFormValid}
          sx={{
            backgroundColor: isFormValid ? (hasBeenAdded ? '#ccc' : '#31303b') : '#ccc',
            color: '#fff',
            marginTop: '2rem',
            width: '250px',
            borderRadius: '10px',
            marginLeft: { xs: '2rem', sm: '7rem' },
            '&:hover': { backgroundColor: isFormValid ? (hasBeenAdded ? '#ccc' : '#000') : '#ccc' },
          }} >
          Add To Cart </Button>
        <Modal
          open={openModal}
          onClose={handleCancel}
          aria-labelledby="booking-for-others-modal"
          aria-describedby="booking-for-others-details"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { sm: 400, xs: 300 },
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '15px',
            }}
          >
            <Typography id="booking-for-others-modal" variant="h6" gutterBottom>
              Booking for Others
            </Typography>

            <TextField
              label="Name"
              fullWidth
              value={otherName}
              onChange={(e) => {
                // Allow only alphabets and spaces, and limit to 20 characters
                const regex = /^[A-Za-z\s]*$/;
                if (e.target.value === '' || (regex.test(e.target.value) && e.target.value.length <= 20)) {
                  setOtherName(e.target.value);
                }
              }}
              sx={{ marginBottom: '1rem' }}
              inputProps={{ maxLength: 20 }} // Just in case to ensure max length
            />

            {/* Mobile Number Input Field */}
            <TextField
              label="Mobile Number"
              fullWidth
              value={otherMobile}
              onChange={(e) => {
                // Allow only numbers
                const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                setOtherMobile(value);
              }}
              sx={{ marginBottom: '1rem' }}
              inputProps={{ maxLength: 10 }} // Limit to 10 digits
            />

            <Box display="flex" justifyContent="space-between">
              {/* Cancel Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                sx={{ backgroundColor: '#31303b' }}
              >
                Cancel
              </Button>

              {/* Save Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // Validate mobile number length
                  if (otherMobile.length !== 10) {
                    // Display toast validation for incomplete phone number
                    toast.error("Phone number is incomplete. Please enter a valid 10-digit number.");
                    return;
                  } else if (otherName.length === 0) {
                    // Display toast validation for incomplete name
                    toast.error("Name is incomplete. Please enter a valid name.");
                    return;
                  }
                  // Proceed with saving if valid
                  handleSave();
                }}
                sx={{ backgroundColor: '#31303b' }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>
      </Paper>
    )} 
  {(showBookingDetails || !isMobile) && (
        <Paper elevation={3} sx={{ marginLeft: { xs: '0', sm: '2rem' }, width: { xs: '80%', sm: '30%' }, padding: '2rem', backgroundColor: '#fff' }}>
        {cartItems.length === 0 ? (
          <Box sx={{ textAlign: 'center', padding: '2rem' }}> <ShoppingCartIcon sx={{ fontSize: '5rem', color: '#ccc' }} />
            <Typography variant="h6">Your cart is empty</Typography>
            <Typography variant="body2">Add some items to your cart!</Typography>
          </Box>
        ) : (
          cartItems.map((item, index) => (
            <Paper key={index} elevation={3} sx={{ padding: '1rem', borderRadius: '15px' }}>
              <Typography sx={{ display: 'flex' }}>
                <Typography variant="h6" sx={{ marginLeft: { xs: '', sm: '2rem' } }}><b>Booking Details</b></Typography>
                <IconButton
                  onClick={clearCart}
                  sx={{
                    position: 'scroll',
                    marginLeft: { xs: '20%', sm: '30%' },
                    right: { xs: '0%', sm: '0%' },
                    top: { xs: '90%', sm: '10%' },
                    color: '#31303b',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>

              <br />
              <hr style={{ marginTop: '0px', marginBottom: "20px", height: "2px", backgroundColor: "black", width: "100%" }} />
              <Typography sx={{ display: 'flex' }}><CalendarMonthIcon />{item.date}</Typography>
              <br />
              <Typography sx={{ display: 'flex' }}>
                {item.sport === 'football' ? <SportsSoccerIcon /> : <SportsCricketIcon />}
                {item.sport.charAt(0).toUpperCase() + item.sport.slice(1)}
              </Typography>
              <br />
              <Typography sx={{ display: 'flex' }}><AccessAlarmIcon />{convertTo12HourFormat(item.selectedTime.start_time)}</Typography>
              <br />
              <Typography sx={{ display: 'flex' }}><CurrencyRupeeIcon />{item.TotalPize}</Typography>
              <br />
              <Typography sx={{ display: 'flex' }}><TimerIcon />{item.duration / 60} Hour{item.duration > 60 ? 's' : ''}</Typography>

              {item.otherName && item.otherMobile && (
                <>
                  <br />
                  <Typography sx={{ display: 'flex' }}><b>Booking for:</b> {item.bookedFor ? "friend" : "self"}</Typography>
                  <br />
                  <Typography sx={{ display: 'flex' }}><b>friend_name:</b> {item.otherName}</Typography>
                  <br />
                  <Typography sx={{ display: 'flex' }}><b>friend_mobile_no:</b> {item.otherMobile}</Typography>
                </>
              )}
            </Paper>
          ))
        )}
        {hasBonusSlot && (
          <>
            <Button
              onClick={handleUseBonusSlot}
              sx={{
                backgroundColor: '#31303b',
                color: '#fff',
                marginTop: { xs: '15px', sm: '15px' },
                width: '200px',
                marginLeft: { xs: '3rem', sm: '6rem' },
                marginBottom: { xs: '10px', sm: '1px' },
                borderRadius: '10px',
                '&:hover': { backgroundColor: '#000', },
              }}
            >
              Use Bonus Slot
            </Button>
          </>
        )}
        <Button onClick={handleProceedToPayment}
          sx={{
            backgroundColor: '#31303b',
            color: '#fff',
            marginTop: { xs: '15px', sm: '15px' },
            width: '200px',
            marginLeft: { xs: '3rem', sm: '6rem' },
            marginBottom: { xs: '10px', sm: '1px' },
            borderRadius: '10px',
            '&:hover': { backgroundColor: '#000', },
          }}>
          Proceed to Payment</Button>

      </Paper>
  )}

      <Dialog open={showSuccessModal} onClose={handleCloseModal}>
        <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="h6">Booking Details</Typography>
          {cartItems.map((item, index) => (
            <Paper key={index} sx={{ padding: '10px', margin: '10px 0' }}>
              <Typography><strong>Date:</strong> {item.date}</Typography>
              <Typography><strong>Sport:</strong> {item.sport.charAt(0).toUpperCase() + item.sport.slice(1)}</Typography>
              <Typography><strong>Time:</strong>{convertTo12HourFormat(item.selectedTime.start_time)}</Typography>
              <Typography><strong>Price:</strong>Bonus Slot</Typography>
              <Typography><strong>Duration:</strong> {item.duration / 60} Hour{item.duration > 60 ? 's' : ''}</Typography>
              {item.otherName && item.otherMobile && (
                <>
                  <Typography><strong>Booking for:</strong> {item.bookedFor ? "friend" : "self"}</Typography>
                  <Typography><strong>Friend's Name:</strong> {item.otherName}</Typography>
                  <Typography><strong>Friend's Mobile:</strong> {item.otherMobile}</Typography>
                </>
              )}
            </Paper>
          ))}
        </Box>
        <DialogTitle>Booking  Successful  <CheckCircleIcon sx={{ color: 'green', fontSize: 16, }} /></DialogTitle>
        <DialogContent>
          <Typography>To Get Your Booking Details Please Click On Below Link</Typography>
          <Button onClick={() => navigate('/profile')} color="primary">Get Booking Details</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </Box>

  );
}
export default Bookingpage;
