import { Typography } from '@mui/material';
import React from 'react';
import "./index.css";
import { Card, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Footerterms() {
  const navigate = useNavigate();

  return (
    <Card>
      <div className='footer-terms'>
        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: { xs: '16px', sm: '16px', md: '18px', lg: '20px' },
            marginTop:{sm:'4px'},
            marginBottom: { xs: '10px', sm: '10px' },
            display: 'flex',
            gap: { xs: '25px', sm: '30px', md: '70px' },
            flexDirection: { xs: 'row', md: 'row' }, 
            flexWrap: { xs: 'wrap', md: 'nowrap' }, 
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            }
          }}
        >
          <Link color="inherit" underline="none" onClick={() => { navigate('/terms') }}>Terms & Conditions</Link>
          <Link color="inherit" underline="none" onClick={() => { navigate('/privacy') }}>Privacy Policy</Link>
          <Link color="inherit" underline="none" onClick={() => { navigate('/contactus') }}>Contact Us</Link>
          <Link color="inherit" underline="none" onClick={() => { navigate('/refund') }}>Refund</Link>
          <Link color="inherit" underline="none" onClick={() => { navigate('/shipping') }}>Shipping</Link>
        </Typography>
      </div>
    </Card>
  );
}

export default Footerterms;
