import sliceCreator from '../../index.js';
import { ADMINGETSLOTS } from '../../../constants/index.js';
const _adminGetSlotsAction = sliceCreator('ADMINGETSLOTS', ADMINGETSLOTS, 'get');

const { asyncAction: getAdminSlotsAction, reducer, clearData: clearGetAdminSlotsAction } = _adminGetSlotsAction;

export default reducer;

export {
    getAdminSlotsAction,
    clearGetAdminSlotsAction
}


