import { padding } from '@mui/system';
import TermsSidebar from './TermsSidebar.js'
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import './index.css'

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box style={{ display: 'flex' }}>
      <TermsSidebar />
      <Box className='terms' style={{ marginTop: '95px', paddingLeft: '3%'}}>
        <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Terms & Conditions</Typography>
        <hr style={{
          margin: '10px 0', border: '1px solid #000',
          width: window.innerWidth <= 576 ? '60%' : window.innerWidth <= 768 ? '40%' : '22%'
        }} />
        <Typography variant='h6' sx={{ marginTop: '10px', marginBottom: '10px' }}>1. Eligibility :</Typography>
        <Typography>
          <ul>
         <li>All users who wish to book and use  <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
            Supert20.in
         </a>  services must provide accurate personal information during registration. There are no age restrictions; however, users below 18 years must obtain parental or guardian consent to participate in activities.</li> </ul>
</Typography>     
   <Typography variant='h6' sx={{ marginTop: '10px' }}>2. Booking And Payments</Typography>
      <ul>
        <li>
          <Typography>
              All bookings made through <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> are <strong>final</strong>. No cancellations or modifications are allowed after a booking has been confirmed.
            </Typography>
          </li>
          <li>
            <Typography>
              Full payment is required at the time of booking. Upon successful payment, users will receive an <strong>on-screen confirmation</strong> of their booking. This confirmation serves as proof of the reservation.
            </Typography>
          </li>
          <li>
            <Typography>
              All payments will be processed through Razorpay, and <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> is not responsible for any payment processing issues that arise from the payment gateway.
            </Typography>
          </li>
        </ul>
        <Typography variant='h6' sx={{ marginTop: '10px' }}>
          3. Liability and Safety
        </Typography>
        <ul>
          <li>
            <Typography>
              <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> is not liable for any <strong>personal injury, loss, or damage</strong> to personal property during the use of the facilities. All participants engage in activities at their own risk.
            </Typography>
          </li>
          <li>
            <Typography>
              It is the responsibility of the user to ensure they are physically fit and capable of participating in sports activities.
            </Typography>
          </li>
          <li>
            <Typography>
              <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> will take reasonable safety measures but does not guarantee the prevention of injuries.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>
          4. User Responsibilities
        </Typography>
        <ul>
          <li>
            <Typography>
              Users must arrive on time for their booked slots and ensure that they <strong>adhere to the facility’s rules.</strong>
            </Typography>
          </li>
          <li>
            <Typography>
              Users are expected to respect the <strong>staff, other players, and the facility</strong>, including keeping the premises clean and undamaged. Any misconduct or damage caused by the user may result in penalties or a ban from future bookings.
            </Typography>
          </li>
        </ul>
        <Typography variant='h6' sx={{ marginTop: '10px' }}>5. No-Show and Late Arrivals</Typography>
        <ul>
          <li>
            <Typography>
              Users who fail to show up for their booking or arrive late will <strong>forfeit</strong> their booking. No refunds or rescheduling will be provided for no-shows or late arrivals.          </Typography>
          </li>
        </ul>
        <Typography variant='h6' sx={{ marginTop: '10px' }}>
          6. Refund and Cancellation Policy
        </Typography>
        <ul>
          <li>
            <Typography>
              <strong>No refunds</strong> will be issued once a booking is confirmed. In the event of a <strong>technical issue or booking error</strong> , refunds will be handled by Razorpay and credited back to the original payment method.
            </Typography>
          </li>
          <li>
            <Typography>
              If the facility is unable to accommodate a booking due to <strong>unforeseen circumstances</strong> (such as a power outage or rain), a <strong>bonus booking slot</strong> will be provided as compensation. No monetary refund will be given.
            </Typography>
          </li>
          <li>
            <Typography>
              <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> reserves the right to <strong>cancel a booking</strong> at its discretion. In such cases, users will be offered a bonus slot as compensation.
            </Typography>
          </li>
        </ul>
        <Typography variant="h6" sx={{ marginTop: '10px' }}>
          7. Data Usage and Privacy
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> collects personal information such as name, email, mobile number, and payment details for the purpose of facilitating bookings and marketing activities.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              User information will not be shared with third parties except for the purposes of processing payments through Razorpay or as required by law.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
                Supert20.in</a> retains the right to use data for promotional purposes. Users may opt out of marketing communications by contacting us.
            </Typography>
          </li>
        </ul>

        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          8. Governing Law
        </Typography>
        <Typography variant="body1">
         <ul><li> All terms and conditions are governed by the laws of <strong>Adilabad, Telangana</strong>. Any disputes arising from the use of Supert20.in will be subject to the exclusive jurisdiction of courts located in Adilabad.</li></ul>
        </Typography>
        <br />
        <br />
        <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: { xs: '16px', sm: '20px', md: '24px' } }}>
          About Our Program: Academy Training for Kids
        </Typography>
        <hr style={{ margin: '10px 0', border: '1px solid #000', width: window.innerWidth <= 576 ? '90%' : window.innerWidth <= 768 ? '40%' : '50%', }} />
        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          1. Parental Consent
        </Typography>
        <Typography variant="body1">
        <ul><li>Enrolment in the academy training program for children requires <strong>parental or guardian consent</strong>. Parents must complete the registration form and agree to the terms outlined by <a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
            Supert20.in</a>, including safety guidelines and behavior policies.</li></ul>
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          2. Code of Conduct for Participants
        </Typography>
        <Typography variant="body1">
        <ul><li>Participants in the academy are expected to demonstrate <strong>sportsmanship</strong>, respect for coaches, and appropriate behavior during training sessions. Failure to adhere to these standards may result in disciplinary action.</li></ul>
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          3. Emergency Procedures
        </Typography>
        <Typography variant="body1">
        <ul><li><a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
         Supert20.in</a> has established protocols for handling <strong>medical issues and emergencies</strong> during academy sessions. Parents will be notified immediately if their child requires medical attention.</li></ul>
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          4. Photography and Media Release
        </Typography>
        <Typography variant="body1">
        <ul><li><a href="https://www.supert20.in" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#000' }}>
          Supert20.in</a> may capture photographs or videos during academy sessions for <strong>promotional purposes</strong>. By enrolling their child, parents consent to the use of these images. Parents who do not wish their child to be photographed can opt out by informing the academy in writing.</li></ul>
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          5. Health and Safety Guidelines
        </Typography>
        <Typography variant="body1">
        <ul><li>The academy will implement necessary health and safety measures, including <strong>first aid</strong> availability and ensuring that sports equipment is safe for use. Parents are encouraged to inform the academy of any medical conditions or allergies their child may have.</li></ul>
        </Typography>
        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          6. Attendance Policy
        </Typography>
        <Typography variant="body1">
        <ul><li>Regular attendance is crucial for progress in the academy. Parents should notify the academy in advance of any absences. Repeated absences without notification may result in the child being removed from the program.</li></ul>
        </Typography>

        <Typography variant="h6" sx={{ marginTop: '10px', marginBottom: '10px' }}>
          7. Behavioral Policies
        </Typography>
        <Typography variant="body1">
        <ul><li> Any inappropriate behavior during academy sessions will not be tolerated. Coaches will inform parents of any issues, and continued misbehavior may result in removal from the program.</li></ul>
        </Typography>
        <br />
        <br />
      </Box>
    </Box>
  );
};

export default Terms;
