import sliceCreator from '../../index.js';
import { SUPERVIOR_VERIFY_OTP } from '../../../constants/index.js';
const _supervisorVerifyOtpAction = sliceCreator('SUPERVIOR_VERIFY_OTP', SUPERVIOR_VERIFY_OTP, 'post');

const { asyncAction: supervisorVerifyOtpAction, reducer, clearData: clearsupervisorVerifyOtpAction } = _supervisorVerifyOtpAction;

export default reducer;

export {
    supervisorVerifyOtpAction,
    clearsupervisorVerifyOtpAction
}


