// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    width: 100%; 
    background-color: #fff; 
    padding-left:  5%; 
    padding-top: 50%;
    border-right: 1px solid #ccc; 
   
  }
  
  .sidebar h2 {
    margin: 0; 
    padding-bottom: 10px; 
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0; 
  }
  
  .sidebar li {
    margin: 10px 0; 
    padding-bottom: 20px;
  }
  .sidebar a {
    text-decoration: none; 
    color: #000; 
    font-weight: bold;
  }
  
  .sidebar a:hover {
    color: #30313b;
  }
  @media (max-width: 768px) {
    .sidebar {
     display: none;
    }

    .sidebar h2 {
      margin: 0;
      padding-bottom: 10px;
    }

    .sidebar ul {
      list-style-type: none;
      padding: 0;
    }

    .sidebar li {
      margin: 10px 0;
      padding-bottom: 20px;
    }

    .sidebar a {
      text-decoration: none;
      color: #000;
      font-weight: bold;
    }

    .sidebar a:hover {
      color: #30313b;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Footer/TermsSidebar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,4BAA4B;;EAE9B;;EAEA;IACE,SAAS;IACT,oBAAoB;EACtB;;EAEA;IACE,qBAAqB;IACrB,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,oBAAoB;EACtB;EACA;IACE,qBAAqB;IACrB,WAAW;IACX,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;EACA;IACE;KACC,aAAa;IACd;;IAEA;MACE,SAAS;MACT,oBAAoB;IACtB;;IAEA;MACE,qBAAqB;MACrB,UAAU;IACZ;;IAEA;MACE,cAAc;MACd,oBAAoB;IACtB;;IAEA;MACE,qBAAqB;MACrB,WAAW;MACX,iBAAiB;IACnB;;IAEA;MACE,cAAc;IAChB;EACF","sourcesContent":[".sidebar {\n    width: 100%; \n    background-color: #fff; \n    padding-left:  5%; \n    padding-top: 50%;\n    border-right: 1px solid #ccc; \n   \n  }\n  \n  .sidebar h2 {\n    margin: 0; \n    padding-bottom: 10px; \n  }\n  \n  .sidebar ul {\n    list-style-type: none;\n    padding: 0; \n  }\n  \n  .sidebar li {\n    margin: 10px 0; \n    padding-bottom: 20px;\n  }\n  .sidebar a {\n    text-decoration: none; \n    color: #000; \n    font-weight: bold;\n  }\n  \n  .sidebar a:hover {\n    color: #30313b;\n  }\n  @media (max-width: 768px) {\n    .sidebar {\n     display: none;\n    }\n\n    .sidebar h2 {\n      margin: 0;\n      padding-bottom: 10px;\n    }\n\n    .sidebar ul {\n      list-style-type: none;\n      padding: 0;\n    }\n\n    .sidebar li {\n      margin: 10px 0;\n      padding-bottom: 20px;\n    }\n\n    .sidebar a {\n      text-decoration: none;\n      color: #000;\n      font-weight: bold;\n    }\n\n    .sidebar a:hover {\n      color: #30313b;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
