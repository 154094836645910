import { Typography, Button, Box } from "@mui/material";
import "./index.css";

import CricketImage from "../../assets/CricketImage.png";

import { useNavigate } from "react-router-dom";

function HeroSection() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: 3 }}>
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h4"
          className="home-title"
          sx={{
            fontSize: { xs: '1.5rem', sm: '2.5rem' },
            fontWeight: 'bold',
            mb: 2,
            marginTop: { xs: '10%', sm: '0%' },
          }}
        >
          Change the way you Play the game.
        </Typography>
        <Typography
          variant="body1"
          className="title"
          sx={{
            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.25rem' },
            mb: 2,
          }}
        >
          Seamlessly explore other Players and play with sports enthusiasts just like you!
        </Typography>
        <Button
          onClick={() => {
            navigate("/booking");
          }}
          variant="contained"
          sx={{
            backgroundColor: "#fff",
            color: "#000",
            borderRadius: 10,
            fontSize: { xs: "0.75rem", sm: "1rem" },
            py: "2%", 
            px: "6%", 
            ml: { xs: "5%", sm: "12%" },
            "&:hover": {
              backgroundColor: "gray",
              color: "#fff",
            },
          }}
        >
          PLAY
        </Button>
      </Box>
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img src={CricketImage} alt="Cricket" className="cricket-image" />
      </Box>
    </Box>
  );
}

export default HeroSection;
