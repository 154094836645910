import sliceCreator from '../../index.js';
import { SEARCHUSER } from '../../../constants/index.js';
const _searchUserAction = sliceCreator('SEARCHUSER', SEARCHUSER, 'get');

const { asyncAction: getSearchUserAction, reducer, clearData: clearGetSearchUserAction } = _searchUserAction;

export default reducer;

export {
    getSearchUserAction,
    clearGetSearchUserAction
}


