import { Box, Typography,Link } from '@mui/material';
import TermsSidebar from './TermsSidebar.js'
import  { useEffect } from 'react';


function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Box style={{ display: 'flex' ,marginBottom:'170px'}}>
            <TermsSidebar />
            <Box style={{  marginTop: '95px', paddingLeft: '3%' }}>
                <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Contact Us</Typography>
                <hr style={{ margin: '10px 0', border: '1px solid #000', width: '37%' }} />
                <Typography> You may contact us using the information below:</Typography><br/>
                <Typography>Super T-20 Cricket Academy & Box Cricket<br/>
                    Beside Rajadhani Hotel<br/>
                    Plot no: 55 & 56 ,TSIIC, Dasnapur<br/>
                    Adilabad-504001<br/>
                    Mobile : 7702737900<br/>
                    Email :<Link> supert20stadium@gmail.com</Link></Typography>
                    <br/>
                    <br/>
            </Box>
        </Box>
    )
}
export default ContactUs;