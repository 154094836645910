// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cricket2{
    height: 15rem;
    width: 25rem;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Booking/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;AAChB","sourcesContent":[".cricket2{\n    height: 15rem;\n    width: 25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
