import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../../screens/Supervisor/Sidebar';
import AdminNavbar from '../../Components/AdminNavbar/AdminNavbar';
import '../../index.css';
 
const SupervisorLayout = () => {
  return (
    <div>
      <header>
        <AdminNavbar userRole={'Supervisor'} />
      </header>
      <main>
        <Sidebar className="admin-sidebar"/>
        <Outlet />
      </main>
      <footer>
        {/* Add your admin footer here */}
      </footer>
    </div>
  );
};
 
export default SupervisorLayout;