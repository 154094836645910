import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, Typography, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getacademySessionsListAction, academySessionBookingAction } from '../../store/actions';
import toast from 'react-hot-toast';

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (`0${today.getMonth() + 1}`).slice(-2);
    const day = (`0${today.getDate()}`).slice(-2); 
    return `${year}-${month}-${day}`;
};

const slotTime = [
  { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
  { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
  { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
  { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
  { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
  { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
  { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
  { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
];

const AcademyAdmissions = () => {
    const dispatch = useDispatch();
    const { successData } = useSelector((state) => state.AcademySessionsListReducer);
    const { successData: academySessionBookingData, error: academySessionBookingError, errorInfo: academySessionBookingErrorInfo } = useSelector((state) => state.academySessionBookingReducer || {});
    const [selectedSession, setSelectedSession] = useState('');
    const [selectedMonth, setSelectedMonth] = useState(getCurrentDate().slice(0, 7));
    const isMobile = useMediaQuery('(max-width:600px)');

    const handleSessionChange = (e) => {
      setSelectedSession(e.target.value);
    };

    const filterSessionsByMonth = (sessions, selectedMonth) => {
      return sessions.filter(session => {
        const sessionMonth = new Date(session.month).toISOString().slice(0, 7);
        return sessionMonth === selectedMonth;
      });
    };

    useEffect(() => {
      dispatch(getacademySessionsListAction());
    }, [dispatch]);

    useEffect(() => {
      if (selectedSession) {
        dispatch(academySessionBookingAction({
          endPoint: `admin/get-academy-bookings?session_id=${selectedSession}`,
        }));
      }
    }, [selectedSession]);

    useEffect(() => {
      if (academySessionBookingError) {
        toast.error(academySessionBookingErrorInfo);
      }
    }, [academySessionBookingError, academySessionBookingErrorInfo]);

    return (
      <Box sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '7%', marginLeft: { xs: '0%', sm: '19%' },
        marginRight: '1%', padding: { xs: '1rem' }
      }}>
        <Typography variant="h4" sx={{ marginBottom: '2rem', color: '#31303b' }}>
          <b>Academy Admissions</b>
        </Typography>
        <Box sx={{ display: 'flex', gap: '1.5rem', width: '100%', justifyContent: 'center', marginBottom: '1.5rem' }}>
          <TextField
            type="month"
            label="Filter by Month"
            variant="outlined"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            sx={{ width: '30%' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            select
            label="Select Session"
            value={selectedSession}
            onChange={handleSessionChange}
            required
            fullWidth
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="">
              -- Select a Session --
            </MenuItem>
            {successData && successData.length > 0 ? (
              filterSessionsByMonth(successData, selectedMonth).map((session) => (
                <MenuItem key={session.session_id} value={session.session_id}>
                  {session.sport} {new Date(session.month).toLocaleString('en-US', { month: 'long' })} ₹{session.price} {slotTime.find(slot => slot.id === session.slot_id)?.start_time || ''}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No sessions available for the selected month</MenuItem>
            )}
          </TextField>
        </Box>

        {selectedSession ? (
          academySessionBookingData ? (
            !isMobile ? (
              <table style={{ width: '80%', borderCollapse: 'collapse', border: '1px solid black', marginTop: '20px' }}>
                <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                  <tr>
                    {['Student Name', "Father's Name", "Father's Number", 'Student Age', 'Student Gender', "school Name"].map((header, index) => (
                      <th key={index} style={{ padding: '8px' }}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Map academySessionBookingData here */}
                  {academySessionBookingData.map((booking, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.student_name|| 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.father_name || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.father_mobile_no || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.student_age || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.student_gender || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'center' }}>{booking.Academy.school_name || 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <Box sx={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '1rem', marginTop: '20px' }}>
                {academySessionBookingData.map((booking, index) => (
                  <Box key={index} sx={{ border: '1px solid black', borderRadius: '8px', padding: '10px' }}>
                    <Typography><b>Student Name:</b> {booking.Academy.student_name || 'N/A'}</Typography>
                    <Typography><b>Father's Name:</b> {booking.Academy.father_name || 'N/A'}</Typography>
                    <Typography><b>Father's Number:</b> {booking.Academy.father_mobile_no || 'N/A'}</Typography>
                    <Typography><b>Student Age:</b> {booking.Academy.student_age || 'N/A'}</Typography>
                    <Typography><b>Student Gender:</b> {booking.Academy.student_gender || 'N/A'}</Typography>
                    <Typography><b>School Name:</b> {booking.Academy.school_name || 'N/A'}</Typography>
                  </Box>
                ))}
              </Box>
            )
          ) : (
            <Typography>There are No Bookings for This Session</Typography>
          )
        ) : (
          <Typography>Please select a session to get details</Typography>
        )}

      </Box>
    );
};

export default AcademyAdmissions;
