import sliceCreator from '../../index.js';
import { ACADEMY_SESSIONS_UPDATE } from '../../../constants/index.js';
const _academySessionUpdateAction = sliceCreator('ACADEMY_SESSIONS_UPDATE', ACADEMY_SESSIONS_UPDATE, 'patch');

const { asyncAction: postAcademySessionsUpdateAction, reducer, clearData: clearPostAcademySessionsUpdateAction } = _academySessionUpdateAction;

export default reducer;

export {
    postAcademySessionsUpdateAction,
    clearPostAcademySessionsUpdateAction
}


