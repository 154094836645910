import {Box, Typography} from '@mui/material'
import TermsSidebar from './TermsSidebar'
import { useEffect } from 'react';


function Shipping() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
       <Box sx={{display:'flex',marginBottom:{xs:'115%',md:'17%',sm:'17%'}}}>
         <TermsSidebar/>
         <Box style={{paddingLeft:'3%'}}>
         <Typography variant='h5' sx={{marginTop: '100px',fontWeight:'bold'}}>Shipping </Typography>
        <hr style={{ margin: '10px 0', border: '1px solid #000', width: '45%' }} />
         <Typography>Shipping is not applicable for business</Typography>
         <br/>
         <br/>
         <br/>
       </Box>
       </Box>
    )
}
export default Shipping;