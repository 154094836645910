import React, { useState, useEffect } from "react";
import { Typography, Box, Card, Grid, CardContent, Avatar, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "@mui/material";
import * as Yup from "yup";
import { getacademyDetailsAction, patchAcademyProfileAction } from "../../store/actions";
import { Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import toast from 'react-hot-toast';

const validationSchema = Yup.object().shape({
  student_name: Yup.string().required("Required"),
  student_gender: Yup.string().required("Required"),
  sport: Yup.string().required("Required"),
});

function AcademyProfile() {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const { successData } = useSelector((state) => state.academyDetailsReducer || []);
  const { successData:studentProfileUpdate } = useSelector((state) => state.academyUpdateProfileReducer || []);
  const [isEditing, setIsEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profiles, setProfiles] = useState([]);
  const [photoFile, setPhotoFile] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  const dispatch = useDispatch();

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setPhotoFile(file);
    setSelectedProfile((prev) => ({ ...prev, photo: URL.createObjectURL(file) }));
  };

  const handleOpenDialog = (profile) => {
    setSelectedProfile(profile);
    setInitialValues(profile);
    setOpenDialog(true);
    setIsEditing(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIsEditing(false);
    setPhotoFile(null);
  };

  useEffect(() => {
    dispatch(getacademyDetailsAction({
      endPoint: `users/academy-details?user_id=${userDetails.id}`,
    }));
  }, [dispatch, userDetails.id,studentProfileUpdate]);

  useEffect(() => {
    if (successData?.data) {
      setProfiles(successData.data);
    }
  }, [successData]);

    const handleSubmit = (values) => {
    const updatedData = { ...values };
    const formDataObj = new FormData();

    // Compare each field with initial values and append only changed fields
    Object.keys(updatedData).forEach((key) => {
      if (updatedData[key] !== initialValues[key]) {
        formDataObj.append(key, updatedData[key]);
      }
    });

    // Append the photoFile if it exists and has changed
    if (photoFile) {
      formDataObj.append("photo", photoFile);
    }

    dispatch(
      patchAcademyProfileAction({
        endPoint: `users/academy-details?academy_id=${updatedData.academy_id}`,
        formDataObj
      })
    );

};  


useEffect(() => {
  if(studentProfileUpdate) {
    toast.success('AcademyProfile updated successfully');
    setIsEditing(false);
    setOpenDialog(false);
     setSelectedProfile(null);
  }

}, [studentProfileUpdate]);
  

  return (
    <Box sx={{
      width: { xs: '100%', sm: '100%' },
      height: 'auto',
      textAlign: 'center',
      marginTop: '2% auto',
      borderTop: '1px solid #ccc',
      overflowX: 'hidden',
    }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '2%', textAlign: 'center' }}>
        Academy Student Profiles
      </Typography>

      {profiles.length > 0 ? (
        <Box sx={{ height: '400px', overflowY: 'auto', overflowX:'hidden',marginTop: '3%',paddingRight:{sm:'',xs:'2.3%'},width:{xs:'91%',sm:'96%'} }}>
          <Grid container spacing={2} sx={{justifyContent: 'center', padding:'0', margin:'0'}}>
            {profiles.map((profile) => (
              <Grid item xs={11} sm={6} md={6} key={profile.id}>
                <Card sx={{ cursor: 'pointer',width:'100%',margin: '0 auto' }} onClick={() => handleOpenDialog(profile)}>
                  <CardContent>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <Avatar src={profile.photo} alt={profile.student_name} sx={{ width: 100, height: 100 }} />
                      <Typography variant="h6">{profile.student_name}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Typography variant="h6" sx={{ marginTop: '2%', textAlign: 'center' }}>
          You currently don't have a registration at the academy
        </Typography>
      )}

      {selectedProfile && (
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
          <DialogTitle textAlign="center" fontWeight="bold">Academy Student Details</DialogTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
              setSubmitting(false);
            }}
            enableReinitialize
          >
            {({ isSubmitting, values, dirty }) => (
              <Form>
               <DialogContent>
  <Box mb={4} display="flex" flexDirection="column" alignItems="center">
    <Avatar src={values.photo} alt={values.student_name} sx={{ width: 150, height: 150, mb: 2 }} />
    {isEditing && (
      <>
        <input
          accept="image/*"
          type="file"
          onChange={handlePhotoChange}
          style={{ display: 'none' }}
          id="photo-upload"
        />
        <label htmlFor="photo-upload">
          <Button variant="contained" component="span" sx={{ backgroundColor: "#31303b" }}>
            Change Photo
          </Button>
        </label>
      </>
    )}
  </Box>

  {Object.keys(values).map((field) => (
    field !== 'academy_id' && field !== 'user_id' && field !== 'photo' && field !== 'enrollment_date' && field !== 'sport' && (
      <Box key={field} mb={2}>
        {isEditing ? (
          field === 'student_gender' ? (
            <Field name={field}>
              {({ field: { value }, form: { setFieldValue } }) => (
                <Select
                  fullWidth
                  value={value || ''}
                  onChange={(e) => setFieldValue('student_gender', e.target.value)}
                  label="STUDENT GENDER"
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              )}
            </Field>
          ) : field === 'grade' ? (
            <Field name={field}>
              {({ field: { value }, form: { setFieldValue } }) => (
                <Select
                  fullWidth
                  value={value || ''}
                  onChange={(e) => setFieldValue('grade', e.target.value)}
                  label="GRADE"
                >
                  {[...Array(12)].map((_, index) => (
                    <MenuItem key={index + 1} value={String(index + 1)}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Field>
          ) : (
            <Field
              as={TextField}
              name={field}
              label={field.replace(/_/g, ' ').toUpperCase()}
              fullWidth
              disabled={field === 'father_name' || field === 'mother_name' }
            />
          )
        ) : (
          <Typography>
            <strong>{field.replace(/_/g, ' ').toUpperCase()}:</strong> {values[field]}
          </Typography>
        )}
      </Box>
    )
  ))}
</DialogContent>

                <DialogActions>
                  {isEditing ? (
                    <>
                      <Button type="submit" disabled={isSubmitting || !dirty}>Save</Button>
                      <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => setIsEditing(true)}>Edit</Button>
                      <Button onClick={handleCloseDialog}>Close</Button>
                    </>
                  )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
    </Box>
  );
}

export default AcademyProfile;