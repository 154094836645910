import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container,Box,Typography,TextField,MenuItem,Divider } from '@mui/material';
import { getacademySessionDetailsAction } from '../../store/slices/academySessionDetails';
import Academy from '../Academy';

const AcademyBooking = () => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const dispatch = useDispatch();
  const {successData} = useSelector((state) => state.academySessionDetailsReducer || {} );
 
  const [selectedSession, setSelectedSession] = useState('');

  const handleSessionChange = (e) => {
    const selectedSessionId = e.target.value;
    setSelectedSession(selectedSessionId);
  
    // Find the selected session object from successData
    const selectedSessionDetails = successData.data.find(session => session.session_id === selectedSessionId);
  
    // If the session is found, store its details in localStorage
    if (selectedSessionDetails) {
      localStorage.setItem('selectedSession', JSON.stringify(selectedSessionDetails));
    }
  };
  
  const slotTime = [
    { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
    { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
    { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
    { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
    { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
    { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
    { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
    { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
  ];
useEffect(() => {
  dispatch(getacademySessionDetailsAction());
}, [dispatch]);


// useEffect(() => {
//   dispatch(getacademyDetailsAction({
//       endPoint: `users/academy-details?user_id=${userDetails?.id}`,
//   }));
// }, [dispatch]);


useEffect(() => {
  localStorage.removeItem('selectedSession');
}, []);

  
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '50%', padding: '20px', marginTop: {xs:'15%',sm:"7%"} }}>
      <Box sx={{ width: '100%', maxWidth: 500, textAlign: 'center', backgroundColor: '#fff', padding: '2%', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" sx={{ fontFamily: 'Arial', color: '#333', marginBottom: 2 }}>
          Available Sessions
        </Typography>
        <Divider sx={{ marginBottom: 2, backgroundColor: '#000', height: 2 }} />
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            select
            label="Select Session"
            value={selectedSession}
            onChange={handleSessionChange}
            required
            fullWidth
            sx={{ marginBottom: 2 }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 300
                  }
                }
              }
            }}
          >
            <MenuItem value="">
              -- Select a Session --
            </MenuItem>
            {successData && successData.data.length > 0 ? (
              successData.data.map((session) => (
                <MenuItem key={session.session_id} value={session.session_id}>
                 {session.sport} {new Date(session.month).toLocaleString('en-US', { month: 'long' })} ₹{session.price} {slotTime.find(slot => slot.id === session.slot_id)?.start_time || ''}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No sessions available</MenuItem>
            )}
          </TextField>
        </Box>
      </Box>
      <Academy />
    </Container>
  );
};

export default AcademyBooking;
