import React from "react";
import { Navigate } from "react-router-dom";


const AdminProtectedRoute = ({ component: Component, ...rest }) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const is_Auth = userDetails?.token;
    const is_Admin = userDetails?.role === "admin";

  return is_Auth && is_Admin ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default AdminProtectedRoute;
