import axios from 'axios';
import { BASE_URL } from '../constants';
import toast from 'react-hot-toast';


export const refreshAccessToken = async () => {
    try {
        const response = await axios.post(`${BASE_URL}auth/refresh-access-token`, {}, { withCredentials: true });
        
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));

        userDetails.token = response.data.accessToken;
        
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        
        return response.data.accessToken; 
    } catch (error) {

        if (error.response?.status === 401 && error.response?.data?.message === "Your session has timed out. Please log in again.") {
            // Handle session expiration
            toast.error('Your session has expired. Please log in again.');
            console.error('Session expired:', error.response.data.message);
            // Clear user details from local storage
            localStorage.clear();
            window.location.href = '/login';
            window.location.reload();
        } else {
            // Handle other errors as needed
            console.error('Refresh token failed:', error);
        }
        throw error; 
    }
}