import sliceCreator from '../../index.js';
import {LOGIN} from '../../../constants/index.js';
const _loginAction = sliceCreator('LOGIN', LOGIN, 'post');

const { asyncAction: loginAction, reducer, clearData: clearLoginData } = _loginAction;

export default reducer;

export {
    loginAction,
    clearLoginData
}


