import sliceCreator from '../../index.js';
import { SUPERVISOR_ACADEMY_SESSION_BOOKING } from '../../../constants/index.js';
const _supervisorrAcademySessionBookingAction = sliceCreator('SUPERVISOR_ACADEMY_SESSION_BOOKING', SUPERVISOR_ACADEMY_SESSION_BOOKING, 'post');

const { asyncAction: supervisorrAcademySessionBookingAction, reducer, clearData: clearSupervisorrAcademySessionBookingAction } = _supervisorrAcademySessionBookingAction;

export default reducer;

export {
    supervisorrAcademySessionBookingAction,
    clearSupervisorrAcademySessionBookingAction
}


