import sliceCreator from '../../index.js';
import { PAYMENT } from '../../../constants/index.js';
const _paymentAction = sliceCreator('PAYMENT', PAYMENT, 'post');

const { asyncAction: getPaymentAction, reducer, clearData: clearGetPaymentAction } = _paymentAction;

export default reducer;

export {
    getPaymentAction,
    clearGetPaymentAction
}


