import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { supervisorBookingDetailsAction } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2);
  const day = (`0${today.getDate()}`).slice(-2); 
  return `${year}-${month}-${day}`;
};

export default function SupervisorDetails() {
  const dispatch = useDispatch();
  const { successData, loading } = useSelector((state) => state.supervisorBookingDetailsReducer);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [filterDate, setFilterDate] = useState(getCurrentDate());
  const [paymentMethod, setPaymentMethod] = useState(''); 

  useEffect(() => {
    dispatch(supervisorBookingDetailsAction());
  }, [dispatch]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  const headers = ["Booking ID", "Date", "Start Time", "Payment Method", "Amount"];

  const filteredData = successData
    ?.filter((booking) => {
      const payment = booking.Payments && booking.Payments.length > 0 ? booking.Payments[0] : null;
      const isDateMatch = !filterDate || booking?.DailySlot?.date === filterDate;
      const isPaymentMethodMatch = !paymentMethod || payment?.payment_method === paymentMethod;
      return isDateMatch && isPaymentMethodMatch;
    }).reverse();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', marginLeft: { md: '220px', xs: '0' } }}>
      <Typography sx={{ marginBottom: '2rem', fontWeight: 'bold',fontSize:{xs:'1.75rem',sm:'2rem'} }}>Supervisor Booking Details</Typography>
      
      <Box sx={{ display: 'flex', gap: '1.5rem', width: '100%', justifyContent: 'center', marginBottom: '1.5rem' }}>
        <TextField
          type="date"
          label="Filter by Date"
          variant="outlined"
          value={filterDate}
          onChange={(e) => setFilterDate(e.target.value)}
          sx={{ marginBottom: '1.5rem', width: '40%' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControl sx={{ marginBottom: '1.5rem', width: '40%' }}>
          <InputLabel>Filter by Payment Method</InputLabel>
          <Select
            value={paymentMethod}
            label="Filter by Payment Method"
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
            <MenuItem value="cash">cash</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!isMobile ? (
            <table style={{ width: '95%', borderCollapse: 'collapse', border: '1px solid black' }}>
              <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={{ padding: '10px', border: '1px solid black' }}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredData?.length > 0 ? (
                  filteredData.map((booking, index) => {
                    const payment = booking.Payments && booking.Payments.length > 0 ? booking.Payments[0] : null;
                    return (
                      <tr key={index}>
                        <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking.booking_id || 'N/A'}</td>
                        <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.DailySlot?.date || 'N/A'}</td>
                        <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{convertTo12HourFormat(booking?.Slot?.start_time) || 'N/A'}</td>
                        <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{payment?.payment_method || 'N/A'}</td>
                        <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{payment?.amount || 'N/A'}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: 'center', border: '1px solid black' }}>No bookings found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <Box sx={{ width: '95%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {filteredData?.length > 0 ? (
                filteredData.map((booking, index) => {
                  const payment = booking.Payments && booking.Payments.length > 0 ? booking.Payments[0] : null;
                  return (
                    <Box key={index} sx={{ border: '1px solid black', padding: '1rem', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
                      <Typography><strong>Booking ID:</strong> {booking.booking_id || 'N/A'}</Typography>
                      <Typography><strong>Date:</strong> {booking?.DailySlot?.date || 'N/A'}</Typography>
                      <Typography><strong>Start Time:</strong> {convertTo12HourFormat(booking?.Slot?.start_time) || 'N/A'}</Typography>
                      <Typography><strong>Payment Method:</strong> {payment?.payment_method || 'N/A'}</Typography>
                      <Typography><strong>Amount:</strong> {payment?.amount || 'N/A'}</Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography>No bookings found.</Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
