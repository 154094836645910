import sliceCreator from '../../index.js';
import { SUPERVISOR_BOOKING_DETAILS } from '../../../constants/index.js';
const _supervisorBookingDetailsAction = sliceCreator('SUPERVISOR_BOOKING_DETAILS', SUPERVISOR_BOOKING_DETAILS, 'get');

const { asyncAction: supervisorBookingDetailsAction, reducer, clearData: clearSupervisorBookingDetailsAction } = _supervisorBookingDetailsAction;

export default reducer;

export {
    supervisorBookingDetailsAction,
    clearSupervisorBookingDetailsAction
}


