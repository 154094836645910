import { useEffect, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Paper, Button, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentAction,clearPostAcademySessionsAction } from '../../store/actions';
import toast from 'react-hot-toast';
// import qr from '../../assets/qr.png'; //  your QR code image


function Payment() {
  //ref for bookingId
  const bookingIdRef = useRef('');

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { successData: BookingsData } = useSelector((state) => state.bookingReducer);
  const { successData:makePayment  ,error: paymentError, errorInfo: paymentErrorInfo } = useSelector((state) => state.paymentReducer);
  const { cartItems = [] } = location.state || {};
  const { bookingDetails } = location.state || {};
  const isAcademyBooking = !!bookingDetails;
  
  

  useEffect(() => {
    if (BookingsData) {
      bookingIdRef.current = BookingsData.booking_id;

    }
  }, [BookingsData]);
  

  let userDetails = JSON.parse(localStorage.getItem("userDetails"));

  let selectedSessionDetails = JSON.parse(localStorage.getItem('selectedSession')) || {};
  const sessionPrice = selectedSessionDetails.price || 0;

  console.log("selectedSessionDetails",sessionPrice)
  
  
  const handleTransactionSubmit = () => {
    let payload;
  
    if (isAcademyBooking) {
      payload = {
        user_id: userDetails.id,
        name: userDetails.name,
        acdbooking_id: bookingDetails.data.newBooking.acdbooking_id,
        amount: sessionPrice,
        payment_method: "Razorpay",
        payment_type: "academy",
        mobileNumber: userDetails.mobile_no,
      };
    } else {
      const totalPrize = cartItems.reduce((total, item) => total + item.TotalPize, 0);
      payload = {
        user_id: userDetails.id,
        booking_id: bookingIdRef.current,
        amount: totalPrize,
        payment_method: "Razorpay",
        payment_type: "slot",
        mobileNumber: userDetails.mobile_no,
      };
    }

    dispatch(getPaymentAction(payload));
  };
  

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  useEffect(() => {
    if (paymentError) {
      toast.error(paymentErrorInfo);
    }
  }, [paymentError]);

  const buttonStyle = {
    backgroundColor: '#31303b',
    color: '#fff',
    cursor: 'pointer',
    margin: '4% 2%',
    borderRadius: '15px',
    '&:hover': { backgroundColor: '#000', color: '#fff' },
  };

  useEffect(() => {
    if (paymentError) {
      toast.error(paymentErrorInfo);
    }
  }, [paymentError]);

  useEffect(() => {
    if (makePayment) {
      console.log(makePayment);
       window.location.href = makePayment.redirectUrl;
    }
  }, [makePayment]);

  const handleCancel = () => {
    navigate("/academy-booking");
      window.location.reload();
  };

  const slotTime = [
    { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
    { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
    { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
    { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
    { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
    { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
    { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
    { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
  ];
 
  return (
    <>
    <Box sx={{ display: 'flex', flexDirection: { xs: 'row', md: 'row' }, gap: '2rem', marginTop: '0%',marginBottom:'2%', padding: '0 1rem',textAlign:'center',alignItems:'center',justifyContent:'center' }}>
       <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '2rem',marginTop:{xs:'25%',sm:'10%'} }}>
        {isAcademyBooking ? (
          <Box sx={{ flex: 1 }}>
            <Typography sx={{fontSize:{xs:'1.5rem',sm:'2rem'}}}>Academy Booking Details</Typography>
            <Paper sx={{ padding: '1rem', borderRadius: '15px', marginTop: '1rem' }}>
              <Typography><strong>Academy ID:</strong> {bookingDetails.data.newBooking.academy_id}</Typography>
              <Typography><strong>Student Name:</strong>{bookingDetails.data.academyDetails.student_name}</Typography>
              <Typography><strong>Father Name:</strong>{bookingDetails.data.academyDetails.father_name}</Typography>
              <Typography><strong>Sport:</strong>{bookingDetails.data.academyDetails.sport}</Typography>
              <Typography><strong>Slot Time:</strong> {slotTime.find(slot => slot.id === bookingDetails.data.session.slot_id)?.start_time || ""}</Typography>
              <Typography><strong>Price:</strong> ₹{sessionPrice}</Typography>
            </Paper>
          </Box>
        ) : (
          <Box sx={{ flex: 1 ,width:'100%'}}>
            <Typography sx={{fontSize:{xs:'1.5rem',sm:'2rem'}}}>Slot Booking Details</Typography>
            {cartItems.map((item, index) => (
              <Paper key={index} sx={{ padding: '1rem', borderRadius: '15px', marginTop: '1rem' }}>
                <Typography><strong>Date:</strong> {item.date}</Typography>
                <Typography><strong>Sport:</strong> {item.sport.charAt(0).toUpperCase() + item.sport.slice(1)}</Typography>
                <Typography><strong>Time:</strong> {convertTo12HourFormat(item.selectedTime.start_time)}</Typography>
                <Typography><strong>Price:</strong> ₹{item.TotalPize}</Typography>
                <Typography><strong>Duration:</strong> {item.duration / 60} Hour{item.duration > 60 ? 's' : ''}</Typography>
                {item.otherName && item.otherMobile && (
                  <>
                    <Typography><strong>Booking for:</strong> {item.bookedFor ? "friend" : "self"}</Typography>
                    <Typography><strong>Friend's Name:</strong> {item.otherName}</Typography>
                    <Typography><strong>Friend's Mobile:</strong> {item.otherMobile}</Typography>
                  </>
                )}
              </Paper>
            ))}
          </Box>
        )}
      </Box>
    </Box>
      <Box sx={{ width: '100%', maxWidth: { xs: '100%', md: '400px' }, alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginLeft: { xs: '0%', sm: '35%' }, marginBottom: { xs: '34%', sm: '7%' } }}>
        <Box sx={{ display: 'flex',justifyContent:'center', width: '100%' }}>
          <Button variant="contained" color="primary" onClick={handleTransactionSubmit} sx={{ ...buttonStyle, width: '45%' }}>
            Pay Now
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCancel} sx={{ ...buttonStyle, width: '45%' }}>
            Cancel
          </Button>
        </Box>
      </Box>
</>
  );
}

export default Payment;
