import { Typography, Box, Button } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";

function HeroRight() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'column' }, 
            }}
        >
            <Button
                sx={{
                    backgroundColor: '#364549',
                    width: { xs: '80%', sm: '80%' },
                    height: { xs: '5%', sm: '8%' },
                    borderRadius: '10px',
                    color: '#fff',
                    marginLeft: { xs: '10%', sm: '10%' },
                    marginTop: { xs: '5%', sm: '10%' },
                    fontSize: { xs: '4vw', sm: '2vw' }, 
                    '&:hover': {
                        backgroundColor: '#fbfcfa',
                        color: 'black',
                    },
                }}
                onClick={() => {
                    navigate('/booking');
                }}
            >
                Book Now
            </Button>
            {/* <Box
                sx={{
                    backgroundColor: '#1e222d',
                    color: '#fff',
                    marginTop: { xs: '5%', sm: '5%' },
                    marginLeft: { xs: '10%', sm: '10%' },
                    width: { xs: '80%', sm: '80%' },
                    height: { xs: 'auto', sm: '15%' },
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2%', // Percentage padding
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: '4vw', sm: '2.5vw' }, // Responsive font size using vw
                        paddingTop: { xs: '2%', sm: '2%' },
                    }}
                >
                    Offers Available
                </Typography>
                <Button
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: '15px',
                        color: '#000',
                        marginTop: { xs: '2%', sm: '2%' },
                        padding: { xs: '3% 5%', sm: '2% 5%' }, // Percentage padding
                    }}
                >
                    Get Flat 10% Off
                </Button>
            </Box> */}
            {/* <Box
                sx={{
                    backgroundColor: '#1e222d',
                    color: '#fff',
                    marginTop: { xs: '5%', sm: '5%' },
                    marginLeft: { xs: '10%', sm: '10%' },
                    width: { xs: '80%', sm: '80%' },
                    height: { xs: 'auto', sm: '15%' },
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2%', // Using percentage padding
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: '4vw', sm: '2.5vw' }, // Font size with vw
                        paddingTop: { xs: '2%', sm: '2%' },
                    }}
                >
                    <b>Timings :</b>
                </Typography>
                <Typography
                    sx={{
                        marginTop: { xs: '1%', sm: '1%' },
                        paddingLeft: '2%',
                    }}
                >
                    <b>5am-12am</b>
                </Typography>
            </Box> */}
            <Box
                sx={{
                    backgroundColor: '#1e222d',
                    color: '#fff',
                    marginTop: { xs: '5%', sm: '5%' },
                    marginLeft: { xs: '5%', sm: '10%' },
                    width: { xs: '86%', sm: '80%' },
                    height: { xs: '50%', sm: '95%' },
                    border: '1px solid #fff',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '2%',
                }}
            >
                <Typography
                    sx={{
                        fontSize: { xs: '4vw', sm: '2.5vw' }, 
                        paddingTop: { xs: '2%', sm: '2%' },
                    }}
                >
                    <b>Location :</b>
                </Typography>
                <Typography
                    sx={{
                        marginTop: { xs: '1%', sm: '1%' },
                        paddingLeft: '2%',
                    }}
                >
                    Super T-20 Stadium <br />
                    near Rajdhani hotel, Dasnapur, Sapthagiri Colony, Dasnapur, chaitanyapuri, Adilabad, Telangana 504001
                </Typography>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4607.103860315001!2d78.52256549837793!3d19.657492209360488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd25dcfed3a2e4b%3A0x2a5a9aaedc0edbb!2sSuper%20T-20%20Stadium!5e0!3m2!1sen!2sin!4v1727506697290!5m2!1sen!2sin" 
                    width="100%" 
                    height="100%"
                    style={{borderRadius:'10px',marginTop:'2%'}}
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"
                />
            </Box>
        </Box>
    );
}

export default HeroRight;
