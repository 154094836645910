import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, CssBaseline, Link, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container } from '@mui/system';
import { registerAction, verifyOtpAction, clearVerifyOtpData, clearRegisterData } from "../../store/actions";
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Authenticate, initOTPless, verifyOTP } from '../../utils/initOtpless';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || '';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState(phoneNumber);
  const phoneNumberRef = useRef('');

  const [gender, setGender] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isMobileDisabled, setIsMobileDisabled] = useState(false);
  const [showSendOtpButton, setShowSendOtpButton] = useState(true);
  const [otpResent, setOtpResent] = useState(false);

  const { successData, error, errorInfo } = useSelector((state) => state.registerReducer);
  const { successData: OtpSucess, error: verifyOtpError, errorInfo: OtpErrorInfo } = useSelector((state) => state.verifyOtpReducer);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobile) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobile);
  };

    const handleSignup = () => {
      if (!name || !gender || !mobile) {
        toast.error('Please fill in all fields.');
        return;
      }

      if (!validateMobileNumber(mobile)) {
        toast.error('Please enter a valid mobile number');
        return;
      }

      if (email && !validateEmail(email)) {
        toast.error('Please enter a valid email address.');
        return;
      }

      
      const userData = {
        full_name: name,
        mobile_no: mobile,
        gender,
      };

      // Include email only if it is provided
      if (email.trim() !== '') {
        userData.email = email;
      }

      setShowOtpInput(true);
      setIsMobileDisabled(true);
      setShowSendOtpButton(false);
      Authenticate({ channel: 'PHONE', phone: mobile, countryCode: '+91' });
      dispatch(registerAction(userData));
    };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setMobile(value);
    phoneNumberRef.current = value;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp.length >= 4) {
      try {
        const response = await verifyOTP({ otp, channel: 'PHONE', phone: mobile, countryCode: '+91' });
        if (!response.success) {
          setOtp('');
          toast.error(response.response.errorMessage);
        }
      } catch (error) {
        setOtp('');
        toast.error('Invalid OTP or OTP expired.');
      }
    } else {
      toast.error('Please enter the OTP.');
    }
  };

  const handleResendOtp = () => {
    // Resend the OTP
    Authenticate({ channel: 'PHONE', phone: mobile, countryCode: '+91' });
    toast.success('OTP resent successfully.');
    setOtpResent(true);
  };

  useEffect(() => {
    if (successData) {
      toast.success('Please enter OTP to verify');
    }
    if (error) {
      toast.error(errorInfo);
      setShowOtpInput(false);
      dispatch(clearRegisterData());
    }
  }, [successData, error, errorInfo]);

  useEffect(() => {
    if (OtpSucess) {
      toast.success("User Registered Successfully");
      localStorage.setItem('userDetails', JSON.stringify(OtpSucess.UserDetails));
      navigate('/');
      window.location.reload(); 

    }
    if (verifyOtpError) {
      toast.error(OtpErrorInfo);
      setShowOtpInput(false);
      setOtp('');
      window.location.reload();
      dispatch(clearVerifyOtpData());
    }
  }, [OtpSucess, verifyOtpError, OtpErrorInfo]);

  useEffect(() => initOTPless(handleUserData), []);

  const handleUserData = (otplessUser) => {
    dispatch(verifyOtpAction({ mobile_no: mobile, status: otplessUser.status }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 13,
          marginBottom: 10,
          padding: 8,
          backgroundColor: '#ffffff',
          borderRadius: '12px',
          boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
          textAlign: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#333' }}>
          Sign Up
        </Typography>
        <hr style={{ marginBottom: "20px", height: "2px", backgroundColor: "black", width: "80%" }} />
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Enter your name"
            style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email (Optional)"
            style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
          />
          <select
            id="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
            style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
          >
            <option value="" disabled>Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
          <input
            type="tel"
            id="mobile"
            value={mobile}
            onChange={handlePhoneNumberChange}
            required
            placeholder="Enter your mobile number"
            maxLength={10}
            disabled={isMobileDisabled}
            style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
          />

          {!showOtpInput && (
            <button
              type="button"
              onClick={handleSignup}
              style={{
                backgroundColor: '#31303b',
                width: '60%',
                color: 'white',
                padding: '10px',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                margin: '10px auto',
                display: 'block'
              }}
            >
              Sign Up
            </button>
          )}

          {showOtpInput && (
            <>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                style={{ width: '100%', padding: '10px', margin: '10px 0', border: '1px solid #ccc', borderRadius: '4px' }}
              />
              <Button
                onClick={handleResendOtp}
                variant="text"
                sx={{
                  color: '#31303b',
                  marginTop: '10px',
                  textTransform: 'none',
                  '&:hover': { textDecoration: 'underline' },
                }}
              >
                Resend OTP
              </Button>

              <button
                type="submit"
                style={{
                  backgroundColor: '#30313b',
                  color: 'white',
                  padding: '10px',
                  width: '60%',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginTop: '10px'
                }}
              >
                Submit
              </button>

            </>
          )}
        </form>
      </Box>
    </Container>
  );
};

export default SignUp;
