import sliceCreator from '../../index.js';
import { SET_HOLIDAY } from '../../../constants/index.js';
const _setHolidayAction = sliceCreator('SET_HOLIDAY', SET_HOLIDAY, 'post');

const { asyncAction: setHolidayAction, reducer, clearData: clearsetHolidayAction } = _setHolidayAction;

export default reducer;

export {
    setHolidayAction,
    clearsetHolidayAction
}


