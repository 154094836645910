import sliceCreator from '../../index.js';
import { ACADEMY_BOOKING_BYUSER } from '../../../constants/index.js';
const _academyBookingByUserAction = sliceCreator('ACADEMY_BOOKING_BYUSER', ACADEMY_BOOKING_BYUSER, 'get');

const { asyncAction: getacademyBookingByUserAction, reducer, clearData: clearAcademyBookingByUserAction } = _academyBookingByUserAction;

export default reducer;

export {
   getacademyBookingByUserAction,
   clearAcademyBookingByUserAction
}


