import React from 'react';
import { Drawer, IconButton, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import Menu from '@mui/icons-material/Menu';
import './TermsSidebar.css'


const Sidebar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <List>
      <ListItem button component="a" href="/terms"   sx={{ color:'#000' }}>
        <ListItemText primary="Terms & Conditions" />
      </ListItem>
      <ListItem button component="a" href="/privacy" sx={{ color:'#000' }}>
        <ListItemText primary="Privacy Policy" />
      </ListItem>
      <ListItem button component="a" href="/contactus" sx={{ color:'#000' }}>
        <ListItemText primary="Contact Us" />
      </ListItem>
      <ListItem button component="a" href="/refund" sx={{ color:'#000' }}>
        <ListItemText primary="Refund" />
      </ListItem>
      <ListItem button component="a" href="/shipping" sx={{ color:'#000' }}>
        <ListItemText primary="Shipping" />
      </ListItem>
    </List>
  );

  return (
    <div>
      {isMobile && (
         <IconButton
         color="white"
         aria-label="open drawer"
         edge="start"
         onClick={handleDrawerToggle}
         sx={{
           position: 'fixed',
           top: 10,
           right: 0,
           color: '#fff',
           mr: 2,
           display: { sm: 'none' },
           zIndex: 1300,
           p: 1,
         }}
       >
         <Menu />
       </IconButton>
      )}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
          },
         
        }}
      >
        {drawer}
      </Drawer>
      {!isMobile && (
        <div className="sidebar">
          {drawer}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
