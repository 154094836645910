
import React from 'react';
import { Link } from 'react-router-dom';
import "./index.css";
import Home from '../Home';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';


const PaymentError = () => {
  const [paymentData, setPaymentData] = useState(null);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/');
  };

  return (
    <div className="payment-error-container">
      <Home />
    <div className="payment-error-overlay">
      <div className="payment-error-popup">  
      <button className="close-button" onClick={handleClose}>&times;</button>    
  
      <div className="error-message">
            <CancelIcon style={{ fontSize: '50px', marginRight: '10px'}} />
            <h1>Payment Failed!</h1>
          </div>     
           <p>We're sorry, but there was an error processing your payment. Please try again or contact support.</p>
           {paymentData && paymentData.paymentType==="slot"&&  <Link to="/booknow" className="button secondary">Try Again</Link>}
           {paymentData && paymentData.paymentType==="academy"&&  <Link to="/payment" className="button secondary">Try Again</Link>}

    </div>
    </div>
    </div>
  );
};

export default PaymentError;