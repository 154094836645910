import sliceCreator from '../../index.js';
import { BOOKING } from '../../../constants/index.js';
const _bookingAction = sliceCreator('BOOKING', BOOKING, 'post');

const { asyncAction: getBookingAction, reducer, clearData: clearGetBookingAction } = _bookingAction;

export default reducer;

export {
    getBookingAction,
    clearGetBookingAction
}


