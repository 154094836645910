import "./index.css";
import { Box, Typography, Button, CssBaseline, TextField, Link } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtpAction, loginAction } from "../../store/actions";
import toast from 'react-hot-toast';
import { Authenticate, initOTPless, verifyOTP } from '../../utils/initOtpless';

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneNumberRef = useRef('');

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpResent, setOtpResent] = useState(false); 

    const { successData: loginData, error: loginError, errorInfo } = useSelector((state) => state.loginReducer);
    const { successData: OtpSucess, error: verifyOtpError, errorInfo: OtpErrorInfo } = useSelector((state) => state.verifyOtpReducer);

    const otpRef = useRef(null);

    useEffect(() => {
        if (loginData) {
            toast.success('OTP sent successfully');
        }
        if (loginError) {
            toast.error(errorInfo);
        }
    }, [loginData, loginError, errorInfo]);

    useEffect(() => {
        if (OtpSucess) {
            toast.success('OTP verified successfully');
            localStorage.setItem('userDetails', JSON.stringify(OtpSucess.UserDetails));
            navigate('/');
            window.location.reload(); 



          
        }
        if (verifyOtpError) {
            toast.error(OtpErrorInfo);
        }
    }, [dispatch, OtpSucess, verifyOtpError, OtpErrorInfo,]);

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        phoneNumberRef.current = value; 
    };

    const handleSendOtp = (e) => {
        e.preventDefault();
        if (phoneNumber.length !== 10) {
            setError('Mobile number must be 10 digits.');
        } else {
            setError('');
            dispatch(loginAction({ mobile_no: phoneNumber }))
                .then((response) => {
                    if (response.payload && response.payload.needsRegistration) {
                        navigate('/signup', { state: { phoneNumber } });
                    } else {
                        Authenticate({ channel: 'PHONE', phone: phoneNumber, countryCode: '+91' });
                        setOtpSent(true);
                        setOtpResent(false); 
                    }
                })
                .catch((error) => {
                    setError('An error occurred. Please try again.');
                });
        }
    };

    const handleOtpChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setOtp(value);
        }
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        if (otp.length < 4 || otp.length > 4) {
            setError('Please enter a valid 4 digit OTP.');
        } else {
            setError('');
            try {
                const response = await verifyOTP({ otp, channel: 'PHONE', phone: phoneNumber, countryCode: '+91' });
                if (!response.success) {
                    setError('invalid OTP or OTP expired. Please try again');
                    setOtp('');
                    toast.error(response.response.errorMessage);
                }  
            } catch (error) {
                setOtp('');
                toast.error(error.errorMessage);
                
            }
        }
    };

    const handleResendOtp = () => {
        if (phoneNumber.length === 10) {
            Authenticate({ channel: 'PHONE', phone: phoneNumber, countryCode: '+91' });
            setOtpResent(true);
            toast.success('OTP resent successfully');
            
        } else {
            setError('Mobile number must be 10 digits.');
        }
    };

    useEffect(() => initOTPless(handleUserData), []);

    const handleUserData = (otplessUser) => {
        dispatch(verifyOtpAction({ mobile_no: phoneNumberRef.current, status: otplessUser.status }));
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ padding: '20px', minHeight: '72.5vh',display: 'flex',flexDirection: 'column',justifyContent: 'center',marginBottom: {xs: 4, sm: 8, md: 12},
        '@media (min-height: 700px) and (max-height: 800px)': {
                marginBottom: 5
            },
            '@media (min-height: 801px) and (max-height: 900px)': {
                marginBottom: 7
            } }}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 13,
                    // marginBottom: {xs:12.5,sm:15},
                    padding: 4,
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.1)',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#333' }}>
                    Login
                </Typography>
                <hr style={{ marginBottom: "20px", height: "2px", backgroundColor: "black", width: "70%" }} />

                {error && (
                    <Typography variant="body2" sx={{ color: 'red', marginBottom: '16px' }}>
                        {error}
                    </Typography>
                )}

                <form onSubmit={otpSent ? handleOtpSubmit : handleSendOtp}>
                    <TextField
                        fullWidth
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Enter Your Mobile Number"
                        variant="outlined"
                        inputProps={{ maxLength: 10 }}
                        disabled={otpSent}
                        sx={{
                            border:'1px solid #ccc',
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '8px',
                                '& fieldset': {
                                    borderColor: '#ccc',
                                    borderWidth: '1px',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#888',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#000',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            },
                            '& input': {
                                textAlign: 'center',
                                padding: '8px',
                                fontSize: '1rem',
                                letterSpacing: '2px',
                            },
                        }}
                    />

                    {otpSent && (
                        <>
                            <TextField
                                fullWidth
                                value={otp}
                                onChange={handleOtpChange}
                                inputRef={otpRef}
                                placeholder="Enter OTP"
                                variant="outlined"
                                inputProps={{ style: { textAlign: 'center', fontSize: '1rem' } }}
                                sx={{
                                    marginTop: '16px',
                                    border:'1px solid #ccc',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '8px',
                                        '& fieldset': {
                                            borderColor: '#ccc',
                                            borderWidth: '1px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#888',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#000',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    },
                                    '& input': {
                                        padding: '8px',
                                        letterSpacing: '2px',
                                        fontSize: '1rem',
                                        '@media (min-width:600px)': { fontSize: '1rem' },
                                    },
                                }}
                            />
                            <Button
                                onClick={handleResendOtp}
                                variant="text"
                                sx={{
                                    color: '#31303b',
                                    marginTop: '10px',
                                    textTransform: 'none',
                                    '&:hover': { textDecoration: 'underline' },
                                }}
                            >
                                Resend OTP
                            </Button>
                            <br/>
                        </>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: '#31303b',
                            color: '#fff',
                            width: '60%',
                            alignSelf: 'center',
                            fontWeight: 'bold',
                            padding: '8px 0',
                            fontSize: '0.875rem',
                            borderRadius: '8px',
                            textTransform: 'none',
                            marginTop:'5%',
                            '&:hover': { backgroundColor: '#31303b' },
                            '&:focus': { backgroundColor: 'black' },
                        }}
                    >
                        {otpSent ? 'Verify OTP' : 'Send OTP'}
                    </Button>
                </form>
            </Box>
        </Container>
    );
}

export default Login;
