import sliceCreator from '../../index.js';
import {LOGOUT} from '../../../constants/index.js';
const _logoutAction = sliceCreator('LOGOUT', LOGOUT, 'post');

const { asyncAction: logoutAction, reducer, clearData: clearLogoutData } = _logoutAction;

export default reducer;

export {
    logoutAction,
    clearLogoutData
}


