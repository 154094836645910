import { Button, Menu, Link, BottomNavigation, BottomNavigationAction, Typography, Toolbar, Box, AppBar, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SuperT20Icon from '../../assets/SuperT20Icon.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';

export default function AdminNavbar({ userRole }) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [previousPage, setPreviousPage] = useState('');

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/', { replace: true });
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleBack = () => {
    navigate('/admin');
  };
  useEffect(() => {
    setPreviousPage(location.pathname);
  }, [location]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: '#31303b',
          padding: { xs: '0 1rem', md: '0 1.5rem' },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: { xs: 1, md: 1 },
            maxWidth: { md: '1200px' },
            margin: '0 auto',
            width: '100%',
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'left',
              fontSize: { xs: '1.25rem', md: '1.5rem' },
              fontWeight: 'bold',
              marginLeft: { xs: 2, sm: 0 },
            }}
          >
            <img src={SuperT20Icon} onClick={() => { navigate('/') }} alt="SuperT20" style={{ width: '150px', height: '50px', marginTop: { sm: '10px' } }} />
          </Typography>


          {/* <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              gap: { xs: 3, md: 4 },
              alignItems: 'center',
              justifyContent: 'center',
              paddingRight: '20%',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/')}>
              HOME
            </Link>
            <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/booking')}>
              PLAY
            </Link>
            <Link color="inherit" underline="none" sx={{ fontSize: '1.15rem' }} onClick={() => navigate('/academy')}>
              JOIN ACADEMY
            </Link>
          </Box> */}
          {userRole !== 'Supervisor' && (
            <Button
              onClick={() => navigate('/supervisor')}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                color: 'white',
                marginRight: '1rem',
              }}
            >
              Go to Supervisor
            </Button>
          )}
          {userRole !== 'admin' && location.pathname.includes('/supervisor') && (
            <Button
              onClick={handleBack}
              sx={{
                display: { xs: 'none', sm: 'flex' },
                color: 'white',
                marginRight: '1rem',
              }}
            >
              Back to Admin
            </Button>
          )}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' },
              backgroundColor: 'gray',
              borderRadius: '20px',
              width: '7rem',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                '&:hover': {
                  backgroundColor: 'gray',
                  borderRadius: '20px',
                },
              }}
            >
              <Button
                onClick={handleProfileMenuOpen}
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black',
                  height: '40px',
                  borderRadius: '20px',
                }}
              >
                <AccountCircleIcon />
                Profile
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{ mt: '5px' }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {userRole !== 'Supervisor' && (
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            position: 'relative',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: '#31303b',
          }}
        >
          <BottomNavigation
            sx={{ backgroundColor: '#31303b', position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 1000 }}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels
          >
            <BottomNavigationAction
              onClick={() => navigate('/')}
              label="Home"
              icon={<HomeIcon />}
              sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
            />
            <BottomNavigationAction
              onClick={() => navigate('/booking')}
              label="Play"
              icon={<PlayCircleFilledIcon />}
              sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
            />
            <BottomNavigationAction
              onClick={() => navigate('/academy')}
              label="Academy"
              icon={<GroupAddOutlinedIcon />}
              sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
            />
            <BottomNavigationAction
              onClick={handleProfileMenuOpen}
              label="Profile"
              icon={<AccountCircleIcon />}
              sx={{ color: 'white', '&.Mui-selected': { color: 'lightblue' } }}
            />
          </BottomNavigation>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: '5px' }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}