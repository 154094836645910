import { registerAction,clearRegisterData } from "../slices/register";
import { loginAction, clearLoginData} from '../slices/login';
import { logoutAction, clearLogoutData } from '../slices/logout';
import { getProfileDetailsAction,clearProfileDetailsData} from '../slices/getprofiledetails';
import { verifyOtpAction,clearVerifyOtpData } from "../slices/verifyotp";
import { getSlotsAction,clearGetSlotsAction } from "../slices/getSlots";
import {getPaymentAction,clearGetPaymentAction } from "../slices/paymentDetails";
import { getBookingAction,clearGetBookingAction } from "../slices/bookingDetails";
import { updateProfileDetailsAction,clearUpdateProfileDetailsAction} from "../slices/updateprofile";
import { userBookingsAction,clearUserBookingsAction } from "../slices/userBookings";
import { getadminDashboardAction,clearGetadminDashboardAction } from "../slices/adminDashboard";
import { getCurrentBookings,clearGetCurrentBookings } from "../slices/adminCurrentBookings";
import {getadminPaymentsAction,clearGetadminPaymentsAction } from "../slices/adminPayments";
import { getSearchUserAction,clearGetSearchUserAction } from "../slices/searchWithMobile";
import { getUpdateUserAction,clearGetUpdateUserAction } from "../slices/updateUser";
import {getAdminSlotsAction,clearGetAdminSlotsAction } from "../slices/adminGetSlots";
import { patchUpdateSlotsAction,clearpatchUpdateSlotsAction } from "../slices/updateSlotDetails";
import { patchUpdateSurgeAmountAction,clearPatchUpdateSurgeAmountAction } from "../slices/updateSurgeAmount";
import { postSupervisorSlotBookingAction,clearPostSupervisorSlotBookingAction } from "../slices/supervisorSlotBooking";
import { supervisorVerifyOtpAction,clearsupervisorVerifyOtpAction } from "../slices/supervisorVerifyOtp";
import { supervisorCreateUserAction,clearSupervisorCreateUserAction } from "../slices/supervisorCreateUser";
import { supervisorDashboardAction,clearSupervisorDashboardAction } from "../slices/supervisorDashboard";
import { supervisorBookingDetailsAction,clearSupervisorBookingDetailsAction } from "../slices/supervisorBookingDetails";
import { academyRegistrationAction,clearAcademyRegistrationAction } from "../slices/academyregistration";
import { postAcademySessionsAction, clearPostAcademySessionsAction } from "../slices/academySessions";
import { getacademySessionsListAction, clearGetacademySessionsListAction } from "../slices/getAcademySessions";
import { getacademyDetailsAction,clearAcademyDetailsAction } from "../slices/academyDetails";
import { getacademySessionDetailsAction,clearAcademySessionDetailsAction } from "../slices/academySessionDetails";
import { postAcademySessionsUpdateAction,clearPostAcademySessionsUpdateAction } from "../slices/academySessionUpdate";
import { setHolidayAction,clearsetHolidayAction } from "../slices/setHoliday";
import { getHolidayAction,cleargetHolidayAction } from "../slices/getHoliday";
import { postBonusSlotBookingAction,clearPostBonusSlotBookingAction } from "../slices/bonusSlotBooking";
import { getacademyBookingByUserAction,clearAcademyBookingByUserAction } from "../slices/academyBookingByUser";
import { postacademyBookingAction,clearAcademyBookingAction } from "../slices/academyBooking";
import { academySessionBookingAction,clearAcademySessionBookingAction} from "../slices/academySessionBookings";
import { patchAcademyProfileAction,clearPatchAcademyProfileAction } from "../slices/updateAcademyProfile";
import { supervisorrAcademySessionBookingAction,clearSupervisorrAcademySessionBookingAction } from "../slices/supervisorAcademyBooking";

export {
    registerAction,
    clearRegisterData,
    loginAction,
    clearLoginData,
    logoutAction,
    clearLogoutData,
    getProfileDetailsAction,
    clearProfileDetailsData,
    verifyOtpAction,
    clearVerifyOtpData,
    getSlotsAction, 
    clearGetSlotsAction,
    getPaymentAction,
    clearGetPaymentAction,
    getBookingAction,
    clearGetBookingAction,
    updateProfileDetailsAction,
    clearUpdateProfileDetailsAction,
    userBookingsAction, 
    clearUserBookingsAction,
    getadminDashboardAction, 
    clearGetadminDashboardAction,
    getCurrentBookings, 
    clearGetCurrentBookings,
    getadminPaymentsAction, 
    clearGetadminPaymentsAction,
    getSearchUserAction, 
    clearGetSearchUserAction,
    getUpdateUserAction, 
    clearGetUpdateUserAction,
    getAdminSlotsAction, 
    clearGetAdminSlotsAction,
    patchUpdateSlotsAction, 
    clearpatchUpdateSlotsAction,
    patchUpdateSurgeAmountAction, 
    clearPatchUpdateSurgeAmountAction,
    postSupervisorSlotBookingAction, 
    clearPostSupervisorSlotBookingAction,
    supervisorVerifyOtpAction, 
    clearsupervisorVerifyOtpAction,
    supervisorCreateUserAction, 
    clearSupervisorCreateUserAction,
    supervisorDashboardAction, 
    clearSupervisorDashboardAction,
    supervisorBookingDetailsAction, 
    clearSupervisorBookingDetailsAction,
    academyRegistrationAction,
    clearAcademyRegistrationAction,
    postAcademySessionsAction,
    clearPostAcademySessionsAction,
    getacademySessionsListAction,
    clearGetacademySessionsListAction,
    getacademyDetailsAction,
    clearAcademyDetailsAction,
    getacademySessionDetailsAction,
    clearAcademySessionDetailsAction,
    postAcademySessionsUpdateAction,
    clearPostAcademySessionsUpdateAction,
    setHolidayAction,
    clearsetHolidayAction,
    getHolidayAction,
    cleargetHolidayAction,
    postBonusSlotBookingAction,
    clearPostBonusSlotBookingAction,
    getacademyBookingByUserAction,
    clearAcademyBookingByUserAction,
    postacademyBookingAction,
    clearAcademyBookingAction,
    academySessionBookingAction,
    clearAcademySessionBookingAction,
    patchAcademyProfileAction,
    clearPatchAcademyProfileAction,
    supervisorrAcademySessionBookingAction,
    clearSupervisorrAcademySessionBookingAction
}