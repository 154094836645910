import sliceCreator from '../../index.js';
import { GET_BOOKINGS } from '../../../constants/index.js';
const _userBookingsAction = sliceCreator('GET_BOOKINGS', GET_BOOKINGS, 'get');

const { asyncAction: userBookingsAction, reducer, clearData: clearUserBookingsAction } = _userBookingsAction;

export default reducer;

export {
    userBookingsAction,
    clearUserBookingsAction
}


