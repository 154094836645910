import sliceCreator from '../../index.js';
import { UPDATESLOTS } from '../../../constants/index.js';
const _updateSlotsAction = sliceCreator('UPDATESLOTS', UPDATESLOTS, 'patch');

const { asyncAction: patchUpdateSlotsAction, reducer, clearData: clearpatchUpdateSlotsAction } = _updateSlotsAction;

export default reducer;

export {
    patchUpdateSlotsAction,
    clearpatchUpdateSlotsAction
}