import sliceCreator from '../../index.js';
import { UPDATESURGEAMOUNT } from '../../../constants/index.js';
const _updateSurgeAmountAction = sliceCreator('UPDATESURGEAMOUNT', UPDATESURGEAMOUNT, 'patch');

const { asyncAction: patchUpdateSurgeAmountAction, reducer, clearData: clearPatchUpdateSurgeAmountAction } = _updateSurgeAmountAction;

export default reducer;

export {
    patchUpdateSurgeAmountAction,
    clearPatchUpdateSurgeAmountAction
}