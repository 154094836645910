import sliceCreator from '../../index.js';
import { GET_SLOTS } from '../../../constants/index.js';
const _slotsAction = sliceCreator('GET_SLOTS', GET_SLOTS, 'get');

const { asyncAction: getSlotsAction, reducer, clearData: clearGetSlotsAction } = _slotsAction;

export default reducer;

export {
    getSlotsAction,
    clearGetSlotsAction
}


