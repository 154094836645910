
import React, { useEffect, useState } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import "./index.css";
import Home from '../Home';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PaymentSuccess = () => {
  const [paymentData, setPaymentData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();


  const slotTime = [
    { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
    { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
    { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
    { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
    { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
    { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
    { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
    { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const data = searchParams.get('data');
    if (data) {
      try {
        const decodedData = JSON.parse(decodeURIComponent(data));
        setPaymentData(decodedData);
      } catch (error) {
        console.error('Error parsing payment data:', error);
      }
    }
  }, [location]);
  const handleClose = () => {
    navigate('/');
  };

const convertTo12Hour = (time) => {
  const [hours, minutes] = time.split(':');
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  return `${hour}:${minutes} ${ampm}`;
};

  return (
    <div className="payment-success-container">
      <Home />
    <div className="payment-success-overlay">
      <div className="payment-success-popup">
      <button className="close-button" onClick={handleClose}>&times;</button>    
      <div className="success-message">
            <CheckCircleIcon style={{ fontSize: '50px', marginRight: '10px' }} />
            <h1>Payment Successful!</h1>
          </div>    
        <p>Thank you for Booking. Your transaction has been completed successfully.</p>
        
        {paymentData && (
          <div className="payment-details">
            <h2>Payment Details:</h2>
            <p><strong>Transaction ID:</strong> {paymentData.transactionId}</p>
            <p><strong>Status:</strong> {paymentData.status}</p>
            <p><strong>Amount:</strong> {paymentData.amount}</p>
    
            {paymentData.paymentType==="slot"&&  <p><strong>Slot Date:</strong>{paymentData.bookingDate} </p>}
            {paymentData.paymentType==="slot"&&  <p><strong>Slot Time:</strong> {slotTime.find(slot => slot.id === paymentData.slotTime)?.start_time || ""}</p>}
            
            {paymentData.message?<h2 style={{color:"red"}}><strong>Message:</strong>{paymentData.message}</h2>:null}

            {paymentData.paymentType==="academy"&&  <p><strong>Session Id:</strong>{paymentData.sessionId} </p>}
            {paymentData.paymentType==="academy"&&  <p><strong>Slot Time:</strong> {slotTime.find(slot => slot.id === paymentData.sessionTime)?.start_time || ""}</p>}
            {paymentData.paymentType==="academy"&&  <p><strong>Month:</strong>{paymentData.sessionMonth} </p>}
            </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default PaymentSuccess;