import sliceCreator from '../../index.js';
import { ADMINDASHBOARD } from '../../../constants/index.js';
const _adminDashboardAction = sliceCreator('ADMINDASHBOARD', ADMINDASHBOARD, 'get');

const { asyncAction: getadminDashboardAction, reducer, clearData: clearGetadminDashboardAction } = _adminDashboardAction;

export default reducer;

export {
    getadminDashboardAction,
    clearGetadminDashboardAction
}


