import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Typography, Box, TextField, Button, Paper, Select, MenuItem, MenuList } from '@mui/material';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TimerIcon from '@mui/icons-material/Timer';
import qrCode from '../../assets/qr.png';
import { supervisorVerifyOtpAction, supervisorCreateUserAction, postSupervisorSlotBookingAction, getSlotsAction } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Authenticate, initOTPless, verifyOTP } from '../../utils/initOtpless';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



export default function SlotBooking() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { successData: supervisorVerifyOtpData, error: supervisorVerifyOtpError, errorInfo: supervisorVerifyOtpErrorInfo } = useSelector((state) => state.supervisorVerifyOtpReducer);
  const { successData: supervisorCreateUserData, error: supervisorCreateUserError, errorInfo: supervisorCreateUserErrorInfo } = useSelector((state) => state.supervisorCreateUserReducer);
  const { successData: supervisorSlotBookingData, error: supervisorSlotBookingError, errorInfo: supervisorSlotBookingErrorInfo } = useSelector((state) => state.supervisorSlotBookingReducer);
  const { successData, loading, } = useSelector((state) => state.getSlotsReducer);

  const [mobileNumber, setMobileNumber] = useState('');
  const mobileNumberRef = useRef('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpResent, setOtpResent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showSlotDropdown, setShowSlotDropdown] = useState(false);
  const [date, setDate] = useState('');
  const [duration, setDuration] = useState(60);
  const [cartItems, setCartItems] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [isBookingConfirmed, setIsBookingConfirmed] = useState(false);
  const otpRef = useRef(null);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [selectedTime, setSelectedTime] = useState({});
  const [enhancedSlots, setEnhancedSlots] = useState([]);
  const [transactionId, setTransactionId] = useState('');
  const [nameError, setNameError] = useState('');
  const totalAmount = parseInt(selectedTime.base_price) + (selectedTime.surge_amount ? parseInt(selectedTime.surge_amount) : 0);



  //user details from local storage
  const user = JSON.parse(localStorage.getItem('userDetails'));
  const today = new Date().toISOString().split('T')[0];





  useEffect(() => {
    if (isBookingConfirmed) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isBookingConfirmed]);

  useEffect(() => {
    if (supervisorVerifyOtpData) {
      // console.log('supervisorVerifyOtpData', supervisorVerifyOtpData);
    }
  })

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setDate(formattedDate);
  }, []);


  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    mobileNumberRef.current = value;
    // console.log('Updated Phone Number Ref:', mobileNumberRef.current);
  };


  const handleSendOtp = (e) => {
    e.preventDefault();
    if (!name) {
      setNameError('Name is required');
      return;
    }
    if (mobileNumber.length !== 10) {
      setError('Mobile number must be 10 digits.');
    } else {
      setError('');
      Authenticate({ channel: 'PHONE', phone: mobileNumber, countryCode: '+91' });
      dispatch(supervisorCreateUserAction({ full_name: name, mobile_no: mobileNumber }))
      setOtpSent(true);
      setOtpResent(false);
      setNameError('');
    }
  };
  const convertTo12HourFormat = (time24) => {
    const [hour, minute] = time24.split(':');
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12; // Convert to 12-hour format
    return `${hour12}:${minute} ${suffix}`;
  };

  const isSlotBeforeCurrentTime = (slotTime) => {
    const currentTime = getCurrentTime();
    return date === new Date().toISOString().split('T')[0] && slotTime < currentTime;
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(' ')[0].slice(0, 5);
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otp.length >= 4) {
      verifyOTP({ otp: otp, channel: 'PHONE', phone: mobileNumber, countryCode: '+91' });
    } else {
      toast.error('Please enter the OTP.');
    }
  };

  const handleResendOtp = () => {
    if (mobileNumber.length === 10) {
      Authenticate({ channel: 'PHONE', phone: mobileNumber, countryCode: '+91' });
      setOtpResent(true);
      toast.success('OTP resent successfully');
      // console.log('OTP resent to:', mobileNumber);
    } else {
      setError('Mobile number must be 10 digits.');
    }
  };
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleBookNow = () => {
    let payload;
    const surgeAmount = selectedTime.surge_amount ? parseInt(selectedTime.surge_amount) : 0;
    const basePrice = parseInt(selectedTime.base_price);
    const TotalPize = basePrice + surgeAmount;

    if (paymentMethod === 'cash') {
      payload = {
        user_id: supervisorVerifyOtpData.user.user_id, // Assuming you have this data from the OTP verification
        daily_slot_id: selectedTime.dailyslot_id,
        booking_type: 'cricket', // Or any other appropriate value
        payment_method: 'cash',
        amount: TotalPize,
        booked_by: user.role
      };
    } else if (paymentMethod === 'UPI') {
      payload = {
        user_id: supervisorVerifyOtpData.user.user_id,
        daily_slot_id: selectedTime.dailyslot_id,
        booking_type: 'cricket',
        transaction_id: transactionId,
        payment_method: 'UPI',
        amount: TotalPize,
        booked_by: user.role

      };
    }

    if (payload) {
      dispatch(postSupervisorSlotBookingAction(payload));
      setIsBookingConfirmed(true);
    } else {
      toast.error('Please select a payment method');
    }
  };


  const handleTimeChange = (event) => {
    const selectedStartTime = event.target.value;


    // Find the corresponding slot details based on selected time
    const matchedStartTime = enhancedSlots.find(slot => slot.start_time === selectedStartTime);
    // console.log("matchedStartTime", matchedStartTime)
    if (matchedStartTime) {

      setSelectedTime(matchedStartTime);  // Store the selected slot details
      setDuration(matchedStartTime.duration);
    }
  };

  useEffect(() => {
    if (successData && successData.data && successData.data.dailyslots && successData.data.dailyslots.length > 0) {
      const enhancedSlotsArray = successData.data.dailyslots
        .filter(dailyslot => dailyslot.date === date)
        .map((dailyslot) => {
          const matchedSlot = successData.data.slots.find(slot => slot.slot_id === dailyslot.slot_id);
          if (matchedSlot) {
            return {
              ...matchedSlot,
              dailyslot_id: dailyslot.dailyslot_id,
              surge_amount: dailyslot.surge_amount,
            };
          }
          return null;
        })
        .filter(Boolean);

      setEnhancedSlots(enhancedSlotsArray);
    }
  }, [successData, date]);

  useEffect(() => {
    if (supervisorCreateUserData) {
      // console.log('Login Data:', supervisorCreateUserData);
      toast.success('OTP sent successfully');
    }
  }, [supervisorCreateUserData]);

  useEffect(() => {
    if (supervisorVerifyOtpData) {
      toast.success('OTP verified successfully');
    }
    if (supervisorVerifyOtpError) {
      toast.error(supervisorVerifyOtpErrorInfo);
      // console.log('Error:', supervisorVerifyOtpErrorInfo);
    }
  }, [dispatch, supervisorVerifyOtpData, supervisorVerifyOtpError, supervisorVerifyOtpErrorInfo]);

  useEffect(() => initOTPless(handleUserData), []);

  const handleUserData = (otplessUser) => {
    // console.log('OTPless User Data:', otplessUser);
    dispatch(supervisorVerifyOtpAction({ mobile_no: mobileNumberRef.current, status: otplessUser.status }));
  };

  useEffect(() => {
    if (supervisorVerifyOtpData) {
      setOtpVerified(true);
    }
    if (supervisorVerifyOtpError) {
      toast.error(supervisorVerifyOtpErrorInfo);
    }
  }, [supervisorVerifyOtpData, supervisorVerifyOtpError]);

  useEffect(() => {
    dispatch(getSlotsAction())
  }, [otpVerified])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showSlotDropdown && !event.target.closest('.slot-dropdown')) {
        setShowSlotDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSlotDropdown]);


  return (
    <Box sx={{ padding: 2, marginLeft: { xs: '0%', sm: '20%' }, marginTop: { xs: '20%', sm: '6%' } }}>
      <Typography variant="h4" sx={{ marginLeft: { xs: '18%', sm: '39%' } }}>Slot Booking</Typography>
      <br />
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, borderRadius: '15px', marginLeft: { xs: '0%', sm: '25%' }, width: { xs: '90%', sm: '45%' } }}>
        <Box sx={{ alignItems: 'center', marginTop: '5%' }}>
          <TextField
            placeholder="Enter Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            disabled={otpVerified}
            sx={{
              width: { xs: '70%', sm: '50%' },
              border: '1px solid #ccc',
              borderRadius: '8px',
              marginLeft: { xs: '15%', sm: '23%' },
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: '#ccc',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: '#888',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
              '& input': {
                textAlign: 'center',
                padding: '8px',
                fontSize: '1rem',
                letterSpacing: '2px',
              },
            }}
          />
          <br />
          <br />
          <TextField
            placeholder="Enter Mobile Number"
            variant="outlined"
            inputProps={{ maxLength: 10 }}
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            required
            disabled={otpVerified}
            sx={{
              width: { xs: '70%', sm: '50%' },
              border: '1px solid #ccc',
              borderRadius: '8px',
              marginLeft: { xs: '15%', sm: '23%' },
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px',
                '& fieldset': {
                  borderColor: '#ccc',
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: '#888',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#000',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
              '& input': {
                textAlign: 'center',
                padding: '8px',
                fontSize: '1rem',
                letterSpacing: '2px',
              },
            }}
          />
        </Box>

        {!otpSent && (
          <Button
            variant="contained"
            sx={{ width: '40%', marginTop: '5%', backgroundColor: '#30313b', marginLeft: '28%' }}
            onClick={handleSendOtp}
          >
            Send OTP
          </Button>
        )}
        {otpSent && (
          <Box>
            <TextField
              placeholder="Enter OTP"
              variant="outlined"
              disabled={otpVerified}
              inputProps={{ maxLength: 6 }}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{
                width: { xs: '70%', sm: '50%' },
                border: '1px solid #ccc',
                marginTop: '5%',
                borderRadius: '8px',
                marginLeft: { xs: '15%', sm: '23%' },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: '#ccc',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#888',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#000',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
                '& input': {
                  textAlign: 'center',
                  padding: '8px',
                  fontSize: '1rem',
                  letterSpacing: '2px',
                },
              }}
            /><br />
            <Button
              onClick={handleResendOtp}
              variant="text"
              sx={{
                color: '#31303b',
                marginTop: '2%',
                textTransform: 'none',
                marginLeft: { xs: '31%', sm: '36%' },
                '&:hover': { textDecoration: 'underline' },
              }}
            >
              Resend OTP
            </Button><br />
            <Button
              variant="contained"
              sx={{ width: '40%', backgroundColor: '#30313b', marginLeft: '28%' }}
              onClick={handleVerifyOtp}
            >
              Verify OTP
            </Button>
          </Box>
        )}
      </Paper>
      {otpVerified && (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, marginTop: '1%' }}>
          <Paper elevation={3} sx={{ padding: '1.5rem', marginTop: '2%', borderRadius: '15px', width: { xs: '85%', sm: '45%' }, backgroundColor: '#fff' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ marginRight: '2%' }}>Date</Typography>
              <TextField
                label="Select Date"
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value)
                  setShowSlotDropdown(false);
                }}
                InputLabelProps={{ shrink: true }}
                required
                inputProps={{
                  min: today,
                }}
                sx={{ marginTop: '1rem', marginLeft: { xs: '20%', sm: '6rem' }, width: { xs: '100%', sm: '100%' } }}
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ marginTop: { xs: '2rem', sm: '2rem' }, marginRight: '0px', marginBottom: { xs: '1rem', sm: '2rem' } }}>Start Time</Typography>
              <Box className="slot-dropdown" sx={{ position: 'relative', width: { xs: '100%', sm: '82%' }, marginLeft: { xs: '6%', sm: '22%' }, marginBottom: { xs: '', sm: '1rem' }, marginTop: { xs: '', sm: '2rem' } }}>
                <TextField
                  value={selectedTime.start_time ? convertTo12HourFormat(selectedTime.start_time) : ''}
                  onClick={() => setShowSlotDropdown(true)}
                  placeholder="Select a slot"
                  sx={{
                    backgroundColor: '#fff',
                    width: {
                      sm: '300px', xs: '90%',
                    },
                    marginLeft: {
                      sm: '0px', xs: '11%'
                    }
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                {showSlotDropdown && (
                  <Box
                    sx={{
                      position: 'absolute',
                      zIndex: 10,
                      backgroundColor: '#fff',
                      border: '1px solid #ccc',
                      borderRadius: '10px',
                      marginTop: '0.5rem',
                      padding: '0.5rem',
                      width: '100%',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#31303b',
                        borderRadius: '8px',
                      },
                      '&::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    {enhancedSlots.length > 0 && enhancedSlots.some(slot => slot.is_active && !isSlotBeforeCurrentTime(slot.start_time)) ? (
                      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                        {enhancedSlots
                          .filter(slot => slot.is_active && !isSlotBeforeCurrentTime(slot.start_time))
                          .map((slot) => (
                            <MenuList
                              sx={{
                                backgroundColor: '#f8f8f8',
                                padding: '0',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                              }}
                            >
                              <MenuItem
                                key={slot.dailyslot_id}
                                onClick={() => {
                                  setSelectedTime(slot);
                                  setShowSlotDropdown(false);
                                }}
                                sx={{
                                  padding: '10px 15px',
                                  backgroundColor: selectedTime.start_time === slot.start_time ? '#31303b' : '#fff',
                                  color: selectedTime.start_time === slot.start_time ? '#fff' : '#000',
                                  borderBottom: '1px solid #f0f0f0',
                                  cursor: 'pointer',
                                  borderRadius: '10px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  '&:hover': {
                                    backgroundColor: 'grey',
                                    borderRadius: '10px',
                                  }
                                }}
                              >
                                {convertTo12HourFormat(slot.start_time)}
                              </MenuItem>
                            </MenuList>
                          ))}
                      </Box>
                    ) : (
                      <MenuItem value="" disabled>
                        {date === today ? "Slots filled for today." : "All Slots are Booked for this Date"}
                      </MenuItem>
                    )}
                  </Box>
                )}


              </Box>

            </div>

            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
              <Typography variant="h6">Duration</Typography>
              <Typography variant="body1" sx={{ fontSize: '18px', minWidth: '80px', textAlign: 'center', marginLeft: { sm: '10%', xs: '10%' } }}>
                {`${Math.floor((duration) / 60)}Hour`}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '2rem', marginBottom: '1rem' }}>
              <Typography variant="h6">Total Amount:</Typography>
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginLeft: '1rem' }}>
                {totalAmount ? `₹${totalAmount}` : 'Please select date and time'}

              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="h6" sx={{ marginTop: '40px' }}>Select Payment Method:</Typography>
              <RadioGroup
                value={paymentMethod}
                onChange={handlePaymentMethodChange}
                sx={{ marginTop: '35px', display: 'flex', flexDirection: 'row', marginLeft: '10px' }}
              >
                <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                {/* <FormControlLabel value="UPI" control={<Radio />} label="UPI"/> */}
              </RadioGroup>
            </Box>
            {paymentMethod === 'cash' && (
              <Button
                onClick={handleBookNow}
                variant="contained"
                sx={{
                  backgroundColor: '#31303b',
                  color: '#fff',
                  marginTop: '2rem',
                  width: '250px',
                  borderRadius: '10px',
                  marginLeft: { xs: '2rem', sm: '7rem' },
                  '&:hover': {
                    backgroundColor: '#000',
                  },
                }}
                disabled={!selectedTime.start_time}
              >
                Book Now
              </Button>
            )}
          </Paper>

          <Paper elevation={3} sx={{ marginLeft: { xs: '0', sm: '2rem' }, width: { xs: '80%', sm: '400px' }, padding: '2rem', backgroundColor: '#fff', marginTop: { xs: '2%' }, borderRadius: '15px' }}>

            {isBookingConfirmed ? (
              <Box sx={{ textAlign: 'center', padding: '2rem' }}>
                <Typography variant="h6">Your Booking Summary</Typography>
                <Typography variant="body1">Date: {date}</Typography>
                <Typography variant="body1">Start Time: {convertTo12HourFormat(selectedTime.start_time)}</Typography>
                <Typography variant="body1">Duration: {Math.floor(duration / 60)}hr</Typography>
                <Typography variant="body1" fontWeight="bold">
                  Total Amount: ₹{parseInt(selectedTime.base_price) + (selectedTime.surge_amount ? parseInt(selectedTime.surge_amount) : 0)}
                </Typography>
                <Typography variant="body1">Payment Method: {paymentMethod}</Typography>
                {paymentMethod === 'UPI' && (
                  <Typography variant="body1">Transaction ID: {transactionId}</Typography>
                )}
              </Box>
            ) : (
              <Box sx={{ textAlign: 'center', padding: '2rem' }}>
                <Typography variant="h6" color="textSecondary">No booking details available</Typography>
              </Box>
            )}

            {!isBookingConfirmed && paymentMethod === 'UPI' && (
              <Box sx={{ textAlign: 'center' }}>
                <img src={qrCode} alt="UPI QR Code" style={{ width: '200px', height: '300px' }} />
                <Typography variant="body1" sx={{ marginTop: '10px' }}>
                  Scan this QR code to pay via UPI
                </Typography>
                <TextField
                  label="Transaction ID"
                  variant="outlined"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  sx={{ marginTop: '20px', width: '60%' }}
                  inputProps={{ maxLength: 12 }}
                />
                <Button
                  variant="contained"
                  onClick={handleBookNow}
                  sx={{
                    backgroundColor: '#31303b',
                    color: '#fff',
                    marginTop: '2rem',
                    width: '60%',
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: '#000',
                    },
                  }}
                  disabled={transactionId.length !== 12 || !selectedTime.start_time}
                >
                  Book Now
                </Button>
              </Box>
            )}

            {isBookingConfirmed && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
                <CheckCircleIcon sx={{ color: 'green', fontSize: 30, marginRight: '10px' }} />
                <Typography variant="h6" sx={{ color: 'green' }}>
                  Booking Confirmed
                </Typography>
              </Box>
            )}

          </Paper>

        </Box>
      )}
    </Box>
  );
}
