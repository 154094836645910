import sliceCreator from '../../index.js';
import {REGISTER} from '../../../constants/index.js';
const _registerAction = sliceCreator('REGISTER', REGISTER, 'post');

const { asyncAction: registerAction, reducer, clearData: clearRegisterData } = _registerAction;

export default reducer;

export {
    registerAction,
    clearRegisterData
}


