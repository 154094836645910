import React from "react";
import { Navigate } from "react-router-dom";


const SupervisorProtectedRoute = ({ component: Component, ...rest }) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const is_Auth = userDetails?.token;
    const is_allow = userDetails?.role === "admin" || userDetails?.role === "supervisor";

  return is_Auth && is_allow ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default SupervisorProtectedRoute;
