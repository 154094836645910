import { Typography,Box } from "@mui/material";
import TermsSidebar from './TermsSidebar.js'
import { useEffect } from "react";

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    return (
        <Box style={{ display: 'flex' }}>
      <TermsSidebar/>
      <Box style={{ marginTop: '95px', paddingLeft: '3%' }}>
      <Typography variant='h5' sx={{fontWeight:'bold'}}>Privacy Policy</Typography>
      <hr style={{ margin: '10px 0', border: '1px solid #000',width:'35%' }} />
      <Typography variant='h6' sx={{marginTop:'30px'}}>Data Collection :</Typography>
      <Typography> Collects name, email, mobile number, payment details, and student info.</Typography>
       <Typography variant='h6' sx={{marginTop:'10px'}}>Third-Party Services:</Typography>
      <Typography> Disclosure regarding Razor pay handling user data.</Typography>
      <Typography variant='h6' sx={{marginTop:'10px'}}>Cookies:</Typography>
      <Typography>No cookies used for tracking.</Typography>
      <Typography variant='h6' sx={{marginTop:'10px'}}>Data Retention :</Typography>
      <Typography>User information retained indefinitely. </Typography>
      <Typography variant='h6' sx={{marginTop:'10px'}}>User Rights :</Typography>
      <Typography>Users can request data deletion and opt out of marketing.</Typography>
      <Typography variant='h6' sx={{marginTop:'10px'}}>Data Security:</Typography>
      <Typography >Data protected using secure AWS servers.</Typography>
      <br/>
      <br/>
      </Box>
    </Box>
    )
}
export default Privacy;