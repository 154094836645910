import sliceCreator from '../../index.js';
import { BONUS_SLOT_BOOKING } from '../../../constants/index.js';
const _bonusSlotBookingAction= sliceCreator('BOOBONUS_SLOT_BOOKING', BONUS_SLOT_BOOKING, 'post');

const { asyncAction: postBonusSlotBookingAction, reducer, clearData: clearPostBonusSlotBookingAction } = _bonusSlotBookingAction;

export default reducer;

export {
    postBonusSlotBookingAction,
    clearPostBonusSlotBookingAction,
}


