import { Typography, Box, Button } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";

import HeroSection from "./HeroSection";
import HeroLeft from "./HeroLeft";
import HeroRight from "./HeroRight";

import SuperT20 from "../../assets/SuperT20.png";

function Home() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: "#000",
        flexWrap: "wrap",
        paddingBottom: { xs: "5%", sm: "2%" },
        paddingTop: "5%",
      }}
    >
      <HeroSection />
      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <HeroLeft />
        <HeroRight />
      </Box>
      <Box className="imagebox" sx={{ position: "relative", overflow: "hidden" }}>
        <img src={SuperT20} alt="SuperT20" className="supert20" />
        <Box
          sx={{
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            width: { xs: "100%", sm: "90%" },
            paddingTop: "1%",
            paddingBottom: "1%",
            color: "white",
            marginTop: "1%",
            marginLeft: "0.5%",
            fontWeight: "bold",
            fontSize: { xs: "3.5vw", sm: "2vw" },
            textAlign: "center",
            paddingLeft: "2%",
            paddingRight: "2%",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Super T-20 Stadium Cricket Academy
        </Box>
        <Box>
          <Typography
            sx={{
              color: "white",
              fontSize: { xs: "3.5vw", sm: "2vw" },
              marginLeft: { xs: "2%", sm: "5%" },
              marginRight: { xs: "2%", sm: "5%" },
            }}
          >
            Elevate your game and unleash your potential at Super T-20 Stadium Cricket Academy. Whether you're a beginner
            or an aspiring pro, our expert coaches provide personalized training to enhance your skills, technique, and
            strategy.
          </Typography>
          <Button
            sx={{
              fontWeight: "bold",
              backgroundColor: "#1c1f26",
              fontSize: { xs: "4vw", sm: "1.5vw" },
              color: "#fff",
              marginTop: "4%",
              marginBottom: "2%",
              marginLeft: { xs: "32%", sm: "42%" },
              marginRight: { xs: "25%", sm: "40%" },
              paddingLeft: "2%",
              paddingTop: "1%",
              paddingBottom: "1%",
              borderRadius: "20px",
              paddingRight: "2%",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#000",
              },
            }}
            onClick={() => {
              navigate("/academy-booking");
            }}
          >
            Join Academy
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
