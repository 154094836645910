import sliceCreator from '../../index.js';
import { ACADEMY_SESSIONS_LIST } from '../../../constants/index.js';
const _getacademySessionsListAction = sliceCreator('ACADEMY_SESSIONS_LIST', ACADEMY_SESSIONS_LIST, 'get');

const { asyncAction: getacademySessionsListAction, reducer, clearData: clearGetacademySessionsListAction } = _getacademySessionsListAction;

export default reducer;

export {
    getacademySessionsListAction,
    clearGetacademySessionsListAction
}


