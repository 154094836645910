import React, { useEffect, useState } from 'react';
import {
    Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem,
    Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import { postAcademySessionsAction, getacademySessionsListAction, postAcademySessionsUpdateAction } from '../../store/actions';
import EditIcon from '@mui/icons-material/Edit';

const AcademySessions = () => {
    const slotTime = [
        { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
        { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
        { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
        { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
        { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
        { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
        { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
        { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
    ];

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSport, setSelectedSport] = useState('');
    const [totalStudents, setTotalStudents] = useState('');
    const [startTime, setStartTime] = useState('');
    const [price, setPrice] = useState('');
    const [formattedDate, setFormattedDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentSession, setCurrentSession] = useState(null); // store session for editing
    const dispatch = useDispatch();
    const { success, loading } = useSelector((state) => state.AcademySessionsReducer);
    const { successData: academySessionsList } = useSelector((state) => state.AcademySessionsListReducer);

    const headers = ["Session ID", "Sport", "Slot ID", "Month", "Price", "Total Students", "Is Filled", 'Actions'];

    const handleSubmit = () => {
        const selectedSlot = slotTime.find(slot => slot.start_time === startTime);
        const slot_id = selectedSlot ? selectedSlot.id : null;
        window.location.reload();
        const data = {
            slot_id: slot_id,
            month: formattedDate,
            sport: selectedSport,
            total_students: totalStudents,
            price: price,
        };
        dispatch(postAcademySessionsAction(data));
    };

    const handleDateChange = (newDate) => {
        if (newDate) {
            const formattedDate = newDate.toLocaleDateString('en-CA');
            setSelectedDate(newDate);
            setFormattedDate(formattedDate);
        } else {
            setSelectedDate(null);
            setFormattedDate(null);
        }
    };

    const handleStartTimeChange = (event) => {
        const selectedTime = event.target.value;
        const selectedSlot = slotTime.find(slot => slot.start_time === selectedTime);

        if (selectedSlot) {
            setStartTime(selectedTime);
        }
    };

    const handleEditClick = (session) => {
        setCurrentSession(session); // store the session details
        setSelectedSport(session.sport);
        setTotalStudents(session.total_students);
        setStartTime(slotTime.find(slot => slot.id === session.slot_id)?.start_time || '');
        setFormattedDate(session.month);
        setSelectedDate(new Date(session.month));
        setOpen(true); // open dialog
    };

    const handleDialogClose = () => {
        setOpen(false);
    };

    const handleSaveChanges = () => {
        window.location.reload();
        dispatch(postAcademySessionsUpdateAction({
            endPoint: `admin/update-academy-session/${currentSession.session_id}`,
            sport: selectedSport,
            total_students: totalStudents,
            slot_id: currentSession.slot_id,
            month: formattedDate,
        }));
        setOpen(false);
    };

    useEffect(() => {
        if (success) {
            setSelectedDate(null);
            setSelectedSport('');
            setTotalStudents('');
            setStartTime('');
            setFormattedDate(null);
            setPrice('');
        }
    }, [success]);

    useEffect(() => {
        dispatch(getacademySessionsListAction());
    }, [dispatch]);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '7%', marginRight: '1%' }}>
            {/* Academy Sessions Form Box */}
            <Box sx={{ marginLeft: '20%', display: 'flex', flexDirection: 'column', marginBottom: '2rem', marginTop: '3%', width: '40%' }}>
                <Typography variant="h4" sx={{ marginBottom: '2rem', fontWeight: 'bold', textAlign: 'center' }}>Academy Session</Typography>

                {/* Date Picker */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        sx={{ marginTop: '2rem' }}
                        label="Select Month and Year"
                        views={['year', 'month']}
                        openTo="month"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>


                {/* Start Time Dropdown */}
                <FormControl fullWidth sx={{ marginTop: '2rem' }}>
                    <InputLabel id="start-time-label">Start Time</InputLabel>
                    <Select
                        labelId="start-time-label"
                        id="start-time-select"
                        value={startTime}
                        label="Start Time"
                        onChange={handleStartTimeChange}
                    >
                        {slotTime.map((slot) => (
                            <MenuItem key={slot.id} value={slot.start_time}>
                                {slot.start_time}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Sport Selection */}
                <FormControl fullWidth sx={{ marginTop: '2rem' }}>
                    <InputLabel id="select-sport-label">Select Sport</InputLabel>
                    <Select
                        labelId="select-sport-label"
                        id="select-sport"
                        value={selectedSport}
                        label="Select Sport"
                        onChange={(e) => setSelectedSport(e.target.value)}
                    >
                        <MenuItem value="cricket">Cricket</MenuItem>
                        <MenuItem value="football">Football</MenuItem>
                    </Select>
                </FormControl>

                {/* Total Max Number of Students */}
                <TextField
                    label="Total Max Number of Students"
                    type="number"
                    value={totalStudents}
                    onChange={(e) => setTotalStudents(e.target.value)}
                    sx={{ marginTop: '2rem' }}
                    fullWidth
                />

                {/* Price Input */}
                <TextField
                    label="Price"
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    sx={{ marginTop: '2rem' }}
                    fullWidth
                />

                {/* Submit Button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginTop: '2rem' }}
                    fullWidth
                >
                    Submit
                </Button>
            </Box>

            {/* Academy Sessions List Box */}
            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', marginLeft: { md: '220px', xs: '0' }, width: '80%'
            }}>
                <Typography variant="h4" sx={{ marginBottom: '2rem', fontWeight: 'bold' }}>Academy Sessions List</Typography>

                {loading ? (
                    <Typography variant="h6">Loading...</Typography>
                ) : (
                    <table style={{ width: '95%', borderCollapse: 'collapse', border: '1px solid black' }}>
                        <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={index} style={{ padding: '10px', border: '1px solid black' }}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {academySessionsList?.map((session, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.session_id}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.sport}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.slot_id}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.month}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.price}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.total_students}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>{session.is_filled ? 'Yes' : 'No'}</td>
                                    <td style={{ padding: '10px', border: '1px solid black' }}>
                                        <EditIcon onClick={() => handleEditClick(session)} style={{ cursor: 'pointer' }} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </Box>

            {/* Edit Dialog */}
            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>Edit Session</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ marginTop: '2rem' }}>
                        <InputLabel id="edit-sport-label">Select Sport</InputLabel>
                        <Select
                            labelId="edit-sport-label"
                            value={selectedSport}
                            onChange={(e) => setSelectedSport(e.target.value)}
                        >
                            <MenuItem value="cricket">Cricket</MenuItem>
                            <MenuItem value="football">Football</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        label="Total Max Number of Students"
                        type="number"
                        value={totalStudents}
                        onChange={(e) => setTotalStudents(e.target.value)}
                        sx={{ marginTop: '2rem' }}
                        fullWidth
                    />

                    {/* Date Picker inside Dialog */}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            sx={{ marginTop: '2rem',width:'100%' }}
                            label="Select Date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>

                    {/* Start Time Dropdown */}
                    <FormControl fullWidth sx={{ marginTop: '2rem' }}>
                        <InputLabel id="edit-start-time-label">Start Time</InputLabel>
                        <Select
                            labelId="edit-start-time-label"
                            value={startTime}
                            onChange={handleStartTimeChange}
                        >
                            {slotTime.map((slot) => (
                                <MenuItem key={slot.id} value={slot.start_time}>
                                    {slot.start_time}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
                    <Button onClick={handleSaveChanges} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AcademySessions;
