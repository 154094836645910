import sliceCreator from '../../index.js';
import { GET_HOLIDAY } from '../../../constants/index.js';
const _getHolidayAction = sliceCreator('GET_HOLIDAY', GET_HOLIDAY, 'get');

const { asyncAction: getHolidayAction, reducer, clearData: cleargetHolidayAction } = _getHolidayAction;

export default reducer;

export {
    getHolidayAction,
    cleargetHolidayAction
}


