import { Typography,Box } from "@mui/material";
import TermsSidebar from './TermsSidebar.js'
import { useEffect } from "react";

function Refund() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box style={{ display: 'flex' }}>
    <TermsSidebar/>
    <Box style={{marginTop: '95px',paddingLeft:'3%'}}>
    <Typography variant='h5' sx={{fontWeight:'bold'}}>Cancellation & Refunds</Typography>
    <hr style={{  margin: '10px 0', border: '1px solid #000', width:
      window.innerWidth <= 576? '70%': window.innerWidth <= 768? '40%':'35%' }}/>   
        <Typography variant='h6' sx={{marginTop:'30px'}}>Cancellation Timeframe :</Typography>
    <Typography>No cancellation permitted</Typography>
     <Typography variant='h6' sx={{marginTop:'10px'}}>Refund Conditions :</Typography>
    <Typography> Refunds for booking issues handled by payment gateway; no refunds otherwise, complemented with bonus slots for unnatural conditions.</Typography>
    <Typography variant='h6' sx={{marginTop:'10px'}}>Non-Refundable Situations:</Typography>
    <Typography>No refunds for no-shows or late arrivals..</Typography>
    <Typography variant='h6' sx={{marginTop:'10px'}}>Admin Cancellations :</Typography>
    <Typography>Users receive a bonus slot for admin cancellations. </Typography>
    <Typography variant='h6' sx={{marginTop:'10px'}}>Payment Methods for Refunds :</Typography>
    <Typography>Refunds to original payment method; timeframe maximum 15 days.</Typography>
    <br/>
    <br/>
    </Box>
  </Box>
  )
}
export default Refund;