import sliceCreator from '../../index.js';
import {ACADEMY_UPDATE_PROFILE} from '../../../constants/index.js';
const _updateAcademyProfileAction = sliceCreator('ACADEMY_UPDATE_PROFILE', ACADEMY_UPDATE_PROFILE , 'patch');

const { asyncAction: patchAcademyProfileAction, reducer, clearData: clearPatchAcademyProfileAction } = _updateAcademyProfileAction;

export default reducer;

export {
    patchAcademyProfileAction,
    clearPatchAcademyProfileAction
}


