import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getCurrentBookings } from '../../store/actions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (`0${today.getMonth() + 1}`).slice(-2);
  const day = (`0${today.getDate()}`).slice(-2); 
  return `${year}-${month}-${day}`;
};

const getMonthDateRange = (monthOffset = 0) => {
  const now = new Date();
  if (monthOffset === -1) {
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);
    return {
      minDate: thirtyDaysAgo.toISOString().split('T')[0],
      maxDate: now.toISOString().split('T')[0]
    };
  } else {
    const thirtyDaysLater = new Date(now);
    thirtyDaysLater.setDate(now.getDate() + 30);
    return {
      minDate: now.toISOString().split('T')[0],
      maxDate: thirtyDaysLater.toISOString().split('T')[0]
    };
  }
};

const CurrentBookings = () => {
  const dispatch = useDispatch();
  const { successData, loading } = useSelector(state => state.adminCurrentBookingsReducer);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedDate, setSelectedDate] = useState('');
  const [monthFilter, setMonthFilter] = useState(0);

  useEffect(() => {
    dispatch(getCurrentBookings());
  }, [dispatch]);

  const convertTo12HourFormat = (time24) => {
    const [hours, minutes] = time24.split(':');
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${period}`;
  };

  const headers = ["Date", 'Name', 'Mobile Number', 'Booking_Id', "Start Time", "Is_Bonus", "Amount"];
  const { minDate, maxDate } = getMonthDateRange(monthFilter);

  const filteredBookings = successData?.filter((booking) => {
    if (selectedDate) {
      return booking?.DailySlot?.date === selectedDate;
    }
    return booking?.DailySlot?.date >= minDate && booking?.DailySlot?.date <= maxDate;
  }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))


  const handleMonthFilterChange = (e) => {
    setMonthFilter(e.target.value);
    setSelectedDate(''); 
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '5rem', marginLeft: { md: '220px', xs: '0' }, marginBottom: { xs: '15%', sm: '2%' } }}>
      <Typography variant="h4" sx={{ marginBottom: '2rem', fontWeight: 'bold' }}>Current Bookings</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '80%' }}>
      <FormControl sx={{ width: '40%', marginBottom: '1.5rem',marginRight:'5%',marginLeft:'10%' }}>
        <InputLabel>Filter by Month</InputLabel>
        <Select
          value={monthFilter}
          label="Filter by Month"
          onChange={handleMonthFilterChange}
        >
          <MenuItem value={-1}>Previous Month</MenuItem>
          <MenuItem value={0}>This Month</MenuItem>
        </Select>
      </FormControl>

      <TextField
        type="date"
        label="Filter by Date"
        variant="outlined"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        sx={{ marginBottom: '1.5rem', width: '40%' }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: minDate,
          max: maxDate
        }}
      />
      </Box>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <>
          {!isMobile ? (
            <table style={{ width: '95%', borderCollapse: 'collapse', border: '1px solid black' }}>
              <thead style={{ backgroundColor: '#31303b', color: 'white' }}>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={{ padding: '10px', border: '1px solid black' }}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredBookings?.length > 0 ? (
                  filteredBookings.map((booking, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.DailySlot?.date || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.user?.full_name || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.user?.mobile_no || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.booking_id || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{convertTo12HourFormat(booking?.Slot.start_time) || 'N/A'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.is_bonus ? 'true' : 'false'}</td>
                      <td style={{ border: '1px solid black', padding: "10px", textAlign: 'center' }}>{booking?.slot?.base_price || 'N/A'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: 'center', border: '1px solid black' }}>No details available for the selected date.</td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <Box sx={{ width: '95%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {filteredBookings?.length > 0 ? (
                filteredBookings.map((booking, index) => (
                  <Box key={index} sx={{ border: '1px solid black', padding: '1rem', borderRadius: '8px', backgroundColor: '#f5f5f5' }}>
                    <Typography><strong>Date:</strong> {booking?.DailySlot?.date || 'N/A'}</Typography>
                    <Typography><strong>Name:</strong> {booking?.user?.full_name || 'N/A'}</Typography>
                    <Typography><strong>Mobile Number:</strong> {booking?.user?.mobile_no || 'N/A'}</Typography>
                    <Typography><strong>Booking ID:</strong> {booking?.booking_id || 'N/A'}</Typography>
                    <Typography><strong>Start Time:</strong> {convertTo12HourFormat(booking?.Slot.start_time) || 'N/A'}</Typography>
                    <Typography><strong>Is Bonus:</strong> {booking?.is_bonus ? 'true' : 'false'}</Typography>
                    <Typography><strong>Amount:</strong> {booking?.slot?.base_price || 'N/A'}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No details available for the selected date.</Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default CurrentBookings;
