import * as React from 'react';
import { useState, useEffect } from 'react';
import { Box, Paper, useMediaQuery, Typography, TextField, IconButton, useTheme, Button, CircularProgress } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchUserAction, getUpdateUserAction } from '../../store/actions';
import EditIcon from '@mui/icons-material/Edit';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import toast from 'react-hot-toast';

export default function SearchWithMobile() {
  const dispatch = useDispatch();
  const { successData, loading } = useSelector((state) => state.searchWithMobileReducer || []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchValue, setSearchValue] = useState("");
  const [tabValue, setTabValue] = useState("0");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isActive, setIsActive] = useState();
  const [isBonus, setIsBonus] = useState();

  const tabNames = ["Profile", "Bookings", "Payments"];

  const handleSearchChange = (e) => {
    const number = e.target.value;
    setSearchValue(number);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSearch = () => {
    if (searchValue) {
      dispatch(
        getSearchUserAction({
          endPoint: `admin/searchUser/?mobile_no=${searchValue}`,
        })
      );
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUpdateProfile = () => {
    dispatch(
      getUpdateUserAction({
        endPoint: `admin/updateUser/${successData?.user.user_id}`,
        updateData: {
          is_active: isActive,
          is_bonus_slot: isBonus,
        }
      })
    );
    toast.success("Profile updated successfully");
    setIsEditing(false);
  };

  useEffect(() => {
    if (successData && successData.user) {
      setIsActive(successData.user.is_active);
      setIsBonus(successData.user.is_bonus_slot);
      setFullName(successData.user.full_name || "");
      setEmail(successData.user.email || "");
      setMobileNo(successData.user.mobile_no || "");
    } else {
      setIsActive(false);
      setIsBonus(false);
      setFullName("");
      setEmail("");
      setMobileNo("");
    }
  }, [successData]);

  return (
    <Box minHeight="100vh" display="flex" justifyContent="center" alignItems="center">
      <Paper
        elevation={3}
        sx={{
          padding: '2rem',
          margin: '2rem',
          borderRadius: '10px',
          marginTop: { xs: '5rem', sm: '7rem' },
          marginLeft: { xs: '10%', sm: '20%' },
          width: isMobile ? '100%' : '60%',
        }}
      >
        {/* Search Section */}
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mb={2}>
          <Typography variant={isMobile ? 'h6' : 'h5'} mr={isMobile ? 0 : 2} mb={isMobile ? 2 : 0} fontWeight={400}>
            Search with Mobile number
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            border={1}
            borderColor="black"
            padding={1}
            borderRadius={1}
            width={isMobile ? '100%' : 'auto'}
          >
            <TextField
              placeholder="Enter Mobile number"
              variant="standard"
              size="medium"
              onChange={handleSearchChange}
              value={searchValue}
              fullWidth={!isMobile}
              InputProps={{
                disableUnderline: true,
                startAdornment: isMobile ? (
                  <IconButton onClick={handleSearch}>
                    <SearchIcon />
                  </IconButton>
                ) : null,
                style: { textAlign: 'center' },
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            {!isMobile && (
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {/* Loading Spinner */}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : (
          // Tabs Section
          successData ? (
            <Box sx={{ width: '100%', maxWidth: '100%', typography: 'body1', mt: 4 }}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    sx={{
                      '& .MuiTabs-scrollButtons': {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    {tabNames.map((name, index) => (
                      <Tab
                        label={name}
                        value={String(index)}
                        key={name}
                        sx={{
                          '&:hover': {
                            color: 'white',
                            backgroundColor: 'primary.main',
                            borderRadius: 1,
                          },
                          fontFamily: 'DM Sans, sans-serif',
                          fontWeight: 500,
                          fontSize: isMobile ? '0.8rem' : '1rem',
                          minWidth: isMobile ? '100px' : '150px',
                          marginX: '8px',
                          '&.Mui-selected': {
                            backgroundColor: 'primary.main',
                            color: 'white',
                            borderRadius: 1,
                          },
                        }}
                      />
                    ))}
                  </TabList>
                </Box>

                {/* Profile Tab Content */}
                <TabPanel value="0" key="profile" sx={{ padding: 0 }}>
                  <Box sx={{ padding: isMobile ? 1 : 2 }} display="flex" alignItems="center">
                    <Typography variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
                      Profile
                    </Typography>
                    <IconButton onClick={() => setIsEditing((prev) => !prev)}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      label="Mobile Number"
                      variant="outlined"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                      fullWidth
                      disabled={!isEditing}
                    />
                  </Box>

                  <Box mb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                          disabled={!isEditing}
                        />
                      }
                      label="Is Active"
                    />
                  </Box>

                  <Box mb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isBonus}
                          onChange={(e) => setIsBonus(e.target.checked)}
                          disabled={!isEditing}
                        />
                      }
                      label="Is Bonus"
                    />
                  </Box>

                  {isEditing && (
                    <Button variant="contained" color="primary" onClick={handleUpdateProfile}>
                      Save Changes
                    </Button>
                  )}
                </TabPanel>

                {/* Bookings Tab Content */}
                <TabPanel value="1" key="bookings" sx={{ padding: 0 }}>
                  <Box sx={{ padding: isMobile ? 1 : 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Bookings
                    </Typography>
                    {successData && successData.bookings ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Booking ID</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>Status</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                          {successData.bookings
                            .filter(booking => booking.status !== "pending")
                            .map((booking,index) => (
                              <TableRow key={index}>
                                <TableCell>{booking.booking_id}</TableCell>
                                <TableCell>{formatDate(booking.created_at)}</TableCell>
                                <TableCell>{booking.status}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography>No bookings found</Typography>
                    )}
                  </Box>
                </TabPanel>

                {/* Payments Tab Content */}
                <TabPanel value="2" key="payments" sx={{ padding: 0 }}>
                  <Box sx={{ padding: isMobile ? 1 : 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Payments
                    </Typography>
                    {successData && successData.payments ? (
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Payment ID</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Transaction ID</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {successData.payments.map((payment, index) => (
                              <TableRow key={index}>
                                <TableCell>{payment.payment_id}</TableCell>
                                <TableCell>{formatDate(payment.payment_date)}</TableCell>
                                <TableCell>{payment.amount}</TableCell>
                                <TableCell>{payment.transaction_id ? payment.transaction_id:"cash"}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Typography>No payments found</Typography>
                    )}
                  </Box>
                </TabPanel>
              </TabContext>
            </Box>
          ) : (
                <Typography variant="h6" sx={{ textAlign: "center", marginY: 2 }}>
                  To Get The Details Of User Please Search With Mobile Number
                </Typography>
          )
        )}
      </Paper>
    </Box>
  );
}
