import { Paper, useTheme, Card, Typography, Button,Box } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import Cricket from "../../assets/Cricket.png";
import Football from "../../assets/Football.png";


import SportsCricketIcon from '@mui/icons-material/SportsCricket';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';


function Booking() {
  const theme = useTheme();
  const navigate = useNavigate();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Paper
        elevation={3}
        sx={{
          margin: theme.spacing(0),
          marginBottom:{xs:'2rem',sm:'5rem'},
          padding: theme.spacing(3),
          borderRadius: theme.shape.borderRadius,
          backgroundColor: '#fff',
          marginTop: { xs: '5rem', sm: '7rem' },
          width: { xs: '85%', sm: '88%' ,md:'90%'},
          height: { xs: '45rem', sm: '23rem' },
          marginLeft: { xs: '0.35rem', sm: '2%',md:'2%' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' ,sm:'row'}, 
            alignItems: 'center', 
          }}
        >
          <Card
            sx={{
              height: '20rem',
              width: {xs:'110%',sm:'25rem'},
              '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease',
              },
              marginBottom: { xs: '2rem', md: '0' },
              marginRight: { xs: '0', md: '5rem' },
              marginRight:{sm:'10%'}
            }}
          >
            <img src={Cricket} alt="Cricket" className="cricket2" />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SportsCricketIcon/>
              <Typography sx={{paddingLeft:{xs:'',sm:'10px'},fontWeight:'bold'}}>Cricket</Typography>
              <Button
              onClick={() => navigate("/Booknow", { state: { selectedSport: "cricket" } })}
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  marginLeft: 'auto', 
                }}
              >
                Book Now
              </Button>
            </Typography>
          </Card>

        
          <Card
            sx={{
              height: '20rem',
              width: {xs:'110%',sm:'25rem'},
              '&:hover': {
                transform: 'scale(1.05)',
                transition: 'transform 0.3s ease',
              },
            }}
          >
            <img src={Football} alt="Cricket" className="cricket2" />
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                padding: '1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <SportsSoccerIcon />
              <Typography sx={{paddingLeft:{xs:'',sm:'10px'},fontWeight:'bold'}}>Football</Typography>
              <Button
              onClick={() => navigate("/Booknow", { state: { selectedSport: "football" } })}
                sx={{
                  backgroundColor: '#000',
                  color: '#fff',
                  marginLeft: 'auto',
                }}
              >
                Book Now
              </Button>
              
            </Typography>
          </Card>
        </Box>
      </Paper>
    </div>
  );
}

export default Booking;
