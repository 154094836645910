import { BrowserRouter as Router, Routes, Route,Navigate  } from 'react-router-dom';


import Layout from './Components/Layout/index.js';
import AdminLayout from './Components/AdminLayout/AdminLayout.js';
import ProtectedRoute from './Components/Hoc/ProtectedRoutes.js';
import Home from "./screens/Home/index.js";
import Booking from "./screens/Booking/index.js";
import Payment from "./screens/Payment/index.js";
import Login from "./screens/Login/index.js";
import Booknow from "./screens/Booking/Booknow.js";
import Profile from "./screens/Profile/index.js";

import Academy from './screens/Academy/index.js';
import AcademyBooking from './screens/Booking/AcademyBooking.js';
import Signup from './screens/Login/Signup.js';
import Dashboard from './screens/AdminPanel/Dashboard.js';
import SupervisorDetails from './screens/AdminPanel/SuperVisorDetails.js';
import AdminPayment from './screens/AdminPanel/AdminPayment.js';
import SearchWithMobile from './screens/AdminPanel/BonusSlot.js';
import CurrentBookings from './screens/AdminPanel/CurrentBookings.js';
import Slots from './screens/AdminPanel/Slots.js'

import Terms from './Components/Footer/Terms&Conditions.js';
import Privacy from './Components/Footer/PrivacyPolicy.js';
import ContactUs from './Components/Footer/ContactUs.js';
import Refund from './Components/Footer/Refund.js';
import Shipping from './Components/Footer/Shipping.js';
import AdminProtectedRoute from './Components/Hoc/AdminProtectedRoutes.js';
import SupervisorProtectedRoute from './Components/Hoc/SupervisorProtectedRoutes.js';

import SupervisorLayout from './Components/SupervisorLayout/SupervisorLayout.js';
import SDashboard from './screens/Supervisor/SDashboard.js';
import SlotBooking from './screens/Supervisor/SlotBooking.js';
import AcademyAdmission from './screens/Supervisor/AcademyAdmission.js';
import AcademyPayment from './screens/Academy/AcademyPayment.js';
import NotFound from './screens/NotFound/index.js';
import AcademySessions from './screens/AdminPanel/AcademySessions.js';
import AcademyAdmissions from './screens/AdminPanel/AcademyAdmissions.js';
import PaymentSuccess from './screens/PaymentSucessPage/index.js';
import PaymentError from './screens/PaymentError/index.js';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout/>}>
        <Route path='/' element={<ProtectedRoute component={Home} />} />
         <Route path='/booking' element={<ProtectedRoute component={Booking} />} />
          <Route path='/booknow' element={<ProtectedRoute component={Booknow} />} />
          <Route path="/login" element={<Login/>}/>
          <Route path="/payment" element={<ProtectedRoute component={Payment} />} />
          <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
          <Route path="/academy" element={<ProtectedRoute component={Academy} />} />
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/academy-booking"element={<ProtectedRoute component={AcademyBooking} />} />
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path='/contactus' element={<ContactUs/>}/>
          <Route path='/refund' element={<Refund/>}/>
          <Route path='/shipping' element={<Shipping/>}/>
          <Route path="/academypayment" element={<AcademyPayment/>}/>
          </Route>

          
          <Route path="/admin" element={<AdminProtectedRoute component={AdminLayout} />}>
          <Route index element={<Navigate to="/admin/dashboard" replace />} />
              <Route path="dashboard" index element={<AdminProtectedRoute component={Dashboard} />} />
              <Route path="currentbookings" element={<AdminProtectedRoute component={CurrentBookings} />} />
              <Route path="supervisors" element={<AdminProtectedRoute component={SupervisorDetails} />} />
              <Route path="admin-payment" element={<AdminProtectedRoute component={AdminPayment} />} />
              <Route path="bonus-slot" element={<AdminProtectedRoute component={SearchWithMobile} />} />
              <Route path="slots" element={<AdminProtectedRoute component={Slots} />} /> 
              <Route path="academy-sessions" element={<AdminProtectedRoute component={AcademySessions} />}/>
              <Route path="academy-adminssions" element={<AdminProtectedRoute component={AcademyAdmissions} />} />
         </Route>

         <Route path="/supervisor" element={<SupervisorProtectedRoute component={SupervisorLayout} />}>
         <Route index element={<Navigate to="/supervisor/sdashboard" replace />} />
          <Route index path="sdashboard" element={<SupervisorProtectedRoute component={SDashboard} />} />
          <Route path="slotbooking" element={<SupervisorProtectedRoute component={SlotBooking} />} />
          <Route path="academyadmission" element={<SupervisorProtectedRoute component={AcademyAdmission} />} />
         </Route>

          <Route path="/payment-success" element={<PaymentSuccess/>} />
          <Route path="/payment-error" element={<PaymentError/>} />

         <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default App;
