import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, TextField, Button, Paper, Container, Divider, MenuItem, Select, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Authenticate, initOTPless, verifyOTP } from '../../utils/initOtpless';
import { getacademySessionDetailsAction, supervisorVerifyOtpAction, supervisorCreateUserAction } from '../../store/actions';
import Academy from './supervisorAcademyBooking';

const AcademyAdmission = () => {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState('');
  const mobileNumberRef = useRef('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpVerified, setOtpVerified] = useState(false);
  const [name, setName] = useState('');
  const [selectedSession, setSelectedSession] = useState('');

  const { successData: academySessionDetails } = useSelector((state) => state.academySessionDetailsReducer || {});
  const { successData: supervisorVerifyOtpData, error: supervisorVerifyOtpError, errorInfo: supervisorVerifyOtpErrorInfo } = useSelector((state) => state.supervisorVerifyOtpReducer);
  const { successData: supervisorCreateUserData } = useSelector((state) => state.supervisorCreateUserReducer);
  const {successData} = useSelector((state) => state.academySessionDetailsReducer || {} );

  const slotTime = [
    { id: 0, start_time: '12:00 AM' }, { id: 1, start_time: '1:00 AM' }, { id: 2, start_time: '2:00 AM' },
    { id: 3, start_time: '3:00 AM' }, { id: 4, start_time: '4:00 AM' }, { id: 5, start_time: '5:00 AM' },
    { id: 6, start_time: '6:00 AM' }, { id: 7, start_time: '7:00 AM' }, { id: 8, start_time: '8:00 AM' },
    { id: 9, start_time: '9:00 AM' }, { id: 10, start_time: '10:00 AM' }, { id: 11, start_time: '11:00 AM' },
    { id: 12, start_time: '12:00 PM' }, { id: 13, start_time: '1:00 PM' }, { id: 14, start_time: '2:00 PM' },
    { id: 15, start_time: '3:00 PM' }, { id: 16, start_time: '4:00 PM' }, { id: 17, start_time: '5:00 PM' },
    { id: 18, start_time: '6:00 PM' }, { id: 19, start_time: '7:00 PM' }, { id: 20, start_time: '8:00 PM' },
    { id: 21, start_time: '9:00 PM' }, { id: 22, start_time: '10:00 PM' }, { id: 23, start_time: '11:00 PM' }
  ];

  useEffect(() => initOTPless(handleUserData), []);

  const handleUserData = (otplessUser) => {
    dispatch(supervisorVerifyOtpAction({ mobile_no: mobileNumberRef.current, status: otplessUser.status }));
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    setMobileNumber(value);
    mobileNumberRef.current = value;
  };
  

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (mobileNumber.length !== 10) {
      toast.error('Mobile number must be 10 digits.');
    } else {
      Authenticate({ channel: 'PHONE', phone: mobileNumber, countryCode: '+91' });
      dispatch(supervisorCreateUserAction({ full_name: name, mobile_no: mobileNumber }));
      setOtpSent(true);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (otp.length >= 4) {
      verifyOTP({ otp: otp, channel: 'PHONE', phone: mobileNumber, countryCode: '+91' });
    } else {
      toast.error('Please enter the OTP.');
    }
  };

  const handleSessionChange = (e) => {
    const selectedSessionId = e.target.value;
    setSelectedSession(selectedSessionId);
  
    // Find the selected session object from successData
    const selectedSessionDetails = successData.data.find(session => session.session_id === selectedSessionId);
  
    // If the session is found, store its details in localStorage
    if (selectedSessionDetails) {
      localStorage.setItem('selectedSession', JSON.stringify(selectedSessionDetails));
    }
  };

  useEffect(() => {
    if (supervisorVerifyOtpData) {
      setOtpVerified(true);
      toast.success('OTP verified successfully');
    }
    if (supervisorVerifyOtpError) {
      toast.error(supervisorVerifyOtpErrorInfo);
    }
  }, [supervisorVerifyOtpData, supervisorVerifyOtpError]);

  useEffect(() => {
    if (otpVerified) {
      dispatch(getacademySessionDetailsAction());
    }
  }, [otpVerified, dispatch]);

 
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop:{xs:'25%',sm: "10%"},marginLeft:{sm:'5%'} }}>
        {!otpVerified && <Typography sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }} className="heading">Academy Booking</Typography>}

      {!otpVerified && (
        <Paper elevation={3} sx={{ padding: 4, marginBottom: 2, borderRadius: '15px', width: '100%', maxWidth: {xs:'300px',sm:'400px'} }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="Enter Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
            />
            <TextField
              label="Enter Mobile Number"
              variant="outlined"
              inputProps={{ maxLength: 10 }}
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              required
              fullWidth
            />
            {!otpSent ? (
              <Button
                variant="contained"
                sx={{ backgroundColor: '#30313b', '&:hover': { backgroundColor: '#1e1f26' } }}
                onClick={handleSendOtp}
                fullWidth
              >
                Send OTP
              </Button>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  label="Enter OTP"
                  variant="outlined"
                  inputProps={{ maxLength: 6 }}
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  fullWidth
                />
                <Button
                  variant="contained"
                  sx={{ backgroundColor: '#30313b', '&:hover': { backgroundColor: '#1e1f26' } }}
                  onClick={handleVerifyOtp}
                  fullWidth
                >
                  Verify OTP
                </Button>
              </Box>
            )}
          </Box>
        </Paper>
      )}
      {otpVerified && (
        <Box sx={{ width: '100%', maxWidth: 500, textAlign: 'center', backgroundColor: '#fff', padding: '2%', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
          <Typography variant="h4" sx={{ fontFamily: 'Arial', color: '#333', marginBottom: 2 }}>
            Academy Booking
          </Typography>
          <Divider sx={{ marginBottom: 2, backgroundColor: '#000', height: 2 }} />
          <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              select
              label="Select Session"
              value={selectedSession}
              onChange={handleSessionChange}
              required
              fullWidth
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="">
                -- Select a Session --
              </MenuItem>
              {academySessionDetails && academySessionDetails.data && academySessionDetails.data.length > 0 ? (
                academySessionDetails.data.map((session) => (
                  <MenuItem key={session.session_id} value={session.session_id}>
                 {session.sport} {new Date(session.month).toLocaleString('en-US', { month: 'long' })} ₹{session.price} {slotTime.find(slot => slot.id === session.slot_id)?.start_time || ''}
                 </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No sessions available</MenuItem>
              )}
            </TextField>
          </Box>
        </Box>
      )}
      {otpVerified && (
        <Academy />
      )}
    </Container>
  );
};

export default AcademyAdmission;
