import sliceCreator from '../../index.js';
import { Current_BOOKINGS } from '../../../constants/index.js';
const _adminCurrentBookings = sliceCreator('Current_BOOKINGS', Current_BOOKINGS, 'get');

const { asyncAction: getCurrentBookings, reducer, clearData: clearGetCurrentBookings } = _adminCurrentBookings;

export default reducer;

export {
    getCurrentBookings,
    clearGetCurrentBookings
}


