import sliceCreator from '../../index.js';
import { SUPERVISOR_DASBOARD } from '../../../constants/index.js';
const _supervisorDashboardAction = sliceCreator('SUPERVISOR_DASBOARD', SUPERVISOR_DASBOARD, 'get');

const { asyncAction: supervisorDashboardAction, reducer, clearData: clearSupervisorDashboardAction } = _supervisorDashboardAction;

export default reducer;

export {
    supervisorDashboardAction,
    clearSupervisorDashboardAction
}


