import sliceCreator from '../../index.js';
import { ACADEMY_BOOKING } from '../../../constants/index.js';
const _academyBookingAction = sliceCreator('ACADEMY_BOOKING', ACADEMY_BOOKING, 'post');

const { asyncAction: postacademyBookingAction, reducer, clearData: clearAcademyBookingAction } = _academyBookingAction;

export default reducer;

export {
   postacademyBookingAction,
   clearAcademyBookingAction
}


