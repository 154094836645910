import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import SchoolIcon from '@mui/icons-material/School'; 
import BlockIcon from '@mui/icons-material/Block';
import { getadminDashboardAction } from '../../store/actions/index.js';
import { useDispatch, useSelector } from 'react-redux';

function DashboardPage() {
const dispatch = useDispatch();
  const { successData } = useSelector(state => state.adminDashboardReducer);

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [inActiveUsers, setInactiveUser] = useState(0);
  const [totalBookings, setTotalBookings] = useState(0); 

  useEffect(() => {
    dispatch(getadminDashboardAction());
  }, [dispatch]);

  useEffect(() => {
    if (successData) {
      setTotalUsers(successData.totalUsers || 0);
      setTotalPayments(successData.totalPayments || 0);
      setInactiveUser(successData.totalInactiveUsers || 0);
      setTotalBookings(successData.totalBookings || 0);
    }
  }, [successData]);

  return (
    <Paper elevation={3} sx={{ padding: '2rem', margin: '2rem', borderRadius:'10px',marginTop: { xs: '5rem', sm: '7rem' }, marginLeft:{xs:'2%',sm:'20%'},width:{xs:'80%',sm:'70%'} }}>
      <Typography variant="h4" sx={{marginLeft:{xs:'0%',sm:'10%'}}}>Hello Admin...!</Typography>
    <Box sx={{ marginTop: { xs: '5%', sm: '2rem' } }}>
      <Box sx={{ display: 'flex',flexDirection:{xs:'column',sm:'row'}, justifyContent: 'space-around', marginBottom: '2rem' }}>
        <Paper elevation={3} sx={{ padding: '2rem',backgroundColor:'#30313b',borderRadius:'10px', width: { xs: '80%', sm: '35%' } ,marginBottom:{xs:'5%',sm:'0%'}}}>
          <Box sx={{ display: 'flex', alignItems: 'center',color:'#fff' }}>
            <PeopleIcon sx={{ fontSize: '4rem', marginRight: '1rem', color: '#fff' }} />
            <Box>
              <Typography variant="h4">{totalUsers}</Typography>
              <Typography variant="subtitle1">Total Users</Typography>
            </Box>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ padding: '2rem',borderRadius:'10px',backgroundColor:'#30313b', width: { xs: '80%', sm: '35%' } }}>
          <Box sx={{ display: 'flex', alignItems: 'start',color:'#fff' }}>
            <CurrencyRupeeIcon sx={{ fontSize: '4rem', marginRight: '1rem', color: '#fff' }} />
            <Box>
              <Typography variant="h4">{totalPayments}</Typography>
              <Typography variant="subtitle1">Total Amount</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>

      <Box sx={{ display: 'flex',flexDirection:{xs:'column',sm:'row'}, justifyContent: 'space-around' }}>
        <Paper elevation={3} sx={{ padding: '2rem',borderRadius:'10px',backgroundColor:'#30313b', width: { xs: '80%', sm: '35%' },marginBottom:{xs:'5%',sm:'0%'} }}>
          <Box sx={{ display: 'flex', alignItems: 'center',color:'#fff' }}>
              <BlockIcon sx={{ fontSize: '4rem', marginRight: '1rem', color: '#fff' }} />
            <Box>
                <Typography variant="h4">{inActiveUsers}</Typography>
              <Typography variant="subtitle1">Blocked User</Typography>
            </Box>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ padding: '2rem',borderRadius:'10px',backgroundColor:'#30313b', width: { xs: '80%', sm: '35%' } }}>
          <Box sx={{ display: 'flex', alignItems: 'center',color:'#fff' }}>
            <SchoolIcon sx={{ fontSize: '4rem', marginRight: '1rem', color: '#fff' }} />
            <Box>
                <Typography variant="h4">{totalBookings}</Typography>
              <Typography variant="subtitle1">Total Bookings</Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
    </Paper>
  );
}

export default DashboardPage;
