// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn1 {
  font-family: sans-serif;
  color: #31303b;
  background-color: #fff;
  line-height: 32px;
  letter-spacing: 1px;
  border-radius: 5px;
  padding: 0px;

}

.btn1:hover {
  background-color: #84838c;
  color: #fff;
  cursor: pointer;
}

.footer {
  background-color: #31303b;
  color: #fff;
 
 
}
.footer-terms{
  background-color: white;
  color:#31303b;
  margin: 0px;
  padding: 0px;
  
}

@media (max-width: 768px) {


  .footer {
    flex-direction: row;
  }



  .btn1 {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .footer {
    margin-bottom: 50px; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,cAAc;EACd,sBAAsB;EACtB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;;AAEd;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,WAAW;;;AAGb;AACA;EACE,uBAAuB;EACvB,aAAa;EACb,WAAW;EACX,YAAY;;AAEd;;AAEA;;;EAGE;IACE,mBAAmB;EACrB;;;;EAIA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".btn1 {\n  font-family: sans-serif;\n  color: #31303b;\n  background-color: #fff;\n  line-height: 32px;\n  letter-spacing: 1px;\n  border-radius: 5px;\n  padding: 0px;\n\n}\n\n.btn1:hover {\n  background-color: #84838c;\n  color: #fff;\n  cursor: pointer;\n}\n\n.footer {\n  background-color: #31303b;\n  color: #fff;\n \n \n}\n.footer-terms{\n  background-color: white;\n  color:#31303b;\n  margin: 0px;\n  padding: 0px;\n  \n}\n\n@media (max-width: 768px) {\n\n\n  .footer {\n    flex-direction: row;\n  }\n\n\n\n  .btn1 {\n    width: 50%;\n  }\n}\n\n@media (max-width: 480px) {\n  .footer {\n    margin-bottom: 50px; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
