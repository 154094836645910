import React from "react";
import { Navigate } from "react-router-dom";


const AdminProtectedRoute = ({ component: Component, ...rest }) => {

  let userDetails = localStorage.getItem("userDetails");
  userDetails = JSON.parse(userDetails);
  const isAuthenticated = !!userDetails?.token;
  console.log(userDetails?.role);




  
 
const renderComponent = () => {
    // if (userDetails?.role === "admin") {
    //   return <Navigate to="/admin" />
    // }
    if (userDetails?.role === "supervisor") {
      return <Navigate to="/supervisor" />
    }
    if (isAuthenticated) {
      return <Component {...rest} />;
    } 
    else {
      return <Navigate to="/Login" />;
    }
  };

  return renderComponent();
};
export default AdminProtectedRoute;



