import sliceCreator from '../../index.js';
import { ACADEMY_DETAILS } from '../../../constants/index.js';
const _academyDetailsAction = sliceCreator('ACADEMY_DETAILS', ACADEMY_DETAILS, 'get');

const { asyncAction: getacademyDetailsAction, reducer, clearData: clearAcademyDetailsAction } = _academyDetailsAction;

export default reducer;

export {
   getacademyDetailsAction,
   clearAcademyDetailsAction
}


