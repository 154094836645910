import sliceCreator from '../../index.js';
import { ADMINPAYMENTS } from '../../../constants/index.js';
const _adminPayments = sliceCreator('ADMINPAYMENTS', ADMINPAYMENTS, 'get');

const { asyncAction: getadminPaymentsAction, reducer, clearData: clearGetadminPaymentsAction } = _adminPayments;

export default reducer;

export {
    getadminPaymentsAction,
    clearGetadminPaymentsAction
}


