import "./index.css";
import { Typography, Button, Box } from "@mui/material";

import Rectangle from "../../assets/Rectangle.png";

import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsCricketIcon from '@mui/icons-material/SportsCricket';

function HeroLeft() {
    return (
        <div>
            <Box>
                <img src={Rectangle} alt="Cricket" className="Rectangle-image" />
            </Box>

            <section sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, gap: 3, mt: 4 }}>
               <Box sx={{display:'flex'}}>
                <Box className='sports'
                    sx={{
                        flex: 1,
                        pt: '3%',
                        pb: {xs:'6%',sm:'3%',md:'6%',lg:'3%'},
                        pl:'3%',
                        pr:'3%',
                        border: '1px solid grey',
                        borderRadius: '10px',
                        backgroundColor: '#31303b',
                        height: { xs: '40%', sm: '45%', md: '50%', lg: '60%' },
                        width: { xs: '90%', sm: '45%', md: '35%', lg: '28%' },
                        marginLeft: { xs: '5%', sm: '5%', md: '3%', lg: '2%' },
                        marginTop: { xs: '8%', sm: '8%', md: '5%', lg: '5%' },
                    }}
                >
                    <Typography sx={{ color: '#fff', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem', lg: '1.6rem' }, mb: 2 }}>
                        Sports Available
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '3%' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: { xs: '4%', sm: '3%', md: '2.5%', lg: '2%' },
                            borderRadius: '10px'
                        }}>
                            <SportsCricketIcon sx={{ width: { xs: '1.5rem', sm: '1.8rem', md: '2.1rem', lg: '2.3rem' }, height: { xs: '1.5rem', sm: '1.8rem', md: '2.1rem', lg: '2.3rem' } }} />
                            <Typography sx={{ margin: '0 5px', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1.1rem', lg: '1.2rem' }, color: '#000', mt: '5%' }}>
                                Cricket
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            padding: { xs: '3%', sm: '3%', md: '2.5%', lg: '2%' },
                            borderRadius: '10px'
                        }}>
                            <SportsSoccerIcon sx={{ width: { xs: '1.5rem', sm: '1.8rem', md: '2.1rem', lg: '2.3rem' }, height: { xs: '1.5rem', sm: '1.8rem', md: '2.1rem', lg: '2.3rem' } }} />
                            <Typography sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1.1rem', lg: '1.2rem' }, color: '#000', mt: '5%' }}>
                                Foot Ball
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{flex: 1,
                        p: '4%', 
                        border: '1px solid grey',
                        borderRadius: '10px',
                        backgroundColor: '#31303b',
                        height: { xs: '40%', sm: '45%' },
                        width: { xs: '20%', sm: '45%' },
                        marginLeft: { xs: '5%', sm: '5%' },
                        marginTop: { xs: '8%', sm: '5%' },
                        marginRight: { xs: '5%', sm: '0%' },
                    }}
                >
                    <Typography sx={{ color: '#fff', fontSize: { xs: '1.2rem', sm: '1.5rem' }, mb: 2 }}>Timings:</Typography>
                    <Typography sx={{ color: '#fff',paddingBottom:{xs:'10%',sm:'28px'}, fontSize: { xs: '1rem', sm: '1.2rem' }, mb: 2 }}>5AM - 12AM</Typography>
                    
                </Box>
                </Box>

                {/* <Box
                    sx={{
                        flex: 1,
                        p: '2%',
                        border: '1px solid grey',
                        borderRadius: '10px',
                        backgroundColor: '#31303b',
                        height: { xs: '30%', sm: '35%' },
                        width: { xs: '90%', sm: '95%' },
                        marginLeft: { xs: '5%', sm: '5%' },
                        marginTop: { xs: '8%', sm: '5%' },
                    }}
                >
                    <Typography sx={{ color: '#fff', fontSize: { xs: '1.2rem', sm: '1.5rem' }, mb: 3 }}>
                        Amenities
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10%' }}>
                        {["Parking", "Cafeteria", "DrinkingWater", "RestRoom", "KidsPlayArea"].map(
                            (amenity, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        mb: '1%',
                                        gap: '1%', // Adjusted spacing
                                        mt: { xs: '5%', sm: '2%' }
                                    }}
                                >
                                    <img src={Select} alt="selected" style={{ marginRight: '1rem' }} />
                                    <Typography sx={{ color: '#fff', fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                                        {amenity}
                                    </Typography>
                                </Box>
                            )
                        )}
                    </Box>
                </Box> */}
            </section>
        </div>
    );
}
export default HeroLeft;
