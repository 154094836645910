import sliceCreator from '../../index.js';
import { ACADEMY_SESSIONS_DETAILS } from '../../../constants/index.js';
const _academySessionDetailsAction = sliceCreator('ACADEMY_SESSION_DETAILS', ACADEMY_SESSIONS_DETAILS, 'get');

const { asyncAction: getacademySessionDetailsAction, reducer, clearData: clearAcademySessionDetailsAction } = _academySessionDetailsAction;

export default reducer;

export {
    getacademySessionDetailsAction,
    clearAcademySessionDetailsAction
}


