import { Container } from "@mui/system";
import { Box, Typography, Button, TextField, Card, CardContent } from "@mui/material";
import "./index.css";
import { useLocation, useNavigate } from "react-router-dom";
import BookIcon from '@mui/icons-material/Book';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from '@mui/material';
import { getProfileDetailsAction, updateProfileDetailsAction, logoutAction, userBookingsAction, getacademyBookingByUserAction } from "../../store/actions/index.js";
import toast from "react-hot-toast";
import AcademyProfile from "./AcademyProfile.js";
import { Drawer } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/icons-material/Menu';


function Profile() {

    let userDetails = localStorage.getItem("userDetails");
    userDetails = JSON.parse(userDetails);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = { name: 'Name', mobile: 'xxxxxxxxxx', email: 'email@gmail.com' };
    const [bookingDetails, setBookingDetails] = useState(initialState);
    const [profile, setProfile] = useState(initialState);
    const [isEditing, setIsEditing] = useState(false);
    const [isBookingsClicked, setIsBookingsClicked] = useState(false);
    const [editedName, setEditedName] = useState(profile.name);
    const [editedMobile, setEditedMobile] = useState(profile.mobile);
    const [editedEmail, setEditedEmail] = useState(profile.email);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('allBookings');
    const [viewType, setViewType] = useState('main');
    const isMobile = useMediaQuery('(max-width:600px)');

    const { successData } = useSelector((state) => state.getProfileDetailsReducer);
    const { successData: updateSuccessData, error: updateError, errorInfo: updateErrorInfo } = useSelector((state) => state.updateProfileReducer);
    const { successData: logoutSuccessData } = useSelector((state) => state.logoutReducer);
    const { successData: bookingsSuccessData, loading, error, errorInfo } = useSelector((state) => state.userBookingsReducer || []);
    const { successData: academyBookingsSuccessData, loading: academyBookingsLoading, error: academyBookingsError, errorInfo: academyBookingsErrorInfo } = useSelector((state) => state.academyBookingByUserReducer || []);

    const handleSlotBookings = () => {
        setViewType('slotBookings');
    };

    const [isAcademyProfileVisible, setIsAcademyProfileVisible] = useState(false);

    const handleAcademyBookings = () => {
        setViewType('academyBookings');
        setIsBookingsClicked(true);
        setIsEditing(false);
        dispatch(getacademyBookingByUserAction({ endPoint: `users/academyBookingByUser?user_id=${userDetails.id}` }));
        setIsAcademyProfileVisible(false);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleBack = () => {
        setViewType('main');
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
        setIsBookingsClicked(false);
        setActiveTab('editProfile');
        setIsAcademyProfileVisible(false);
    };

    const handleAcademyProfileClick = () => {
        setIsAcademyProfileVisible(true);
        setIsBookingsClicked(false);
        setIsEditing(false);
        setActiveTab('academyProfile');
    };

    useEffect(() => {
        const storedDetails = localStorage.getItem('bookingDetails');
        if (storedDetails) {
            setBookingDetails(JSON.parse(storedDetails));
        }
    }, []);

    const handleSave = () => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));

        dispatch(updateProfileDetailsAction({
            endPoint: `users/profile?user_id=${userDetails.id}`,
            full_name: editedName,
            mobile_no: editedMobile,
            email: editedEmail
        }));

        setProfile({
            ...profile,
            name: editedName,
            mobile: editedMobile,
            email: editedEmail
        });

        setIsEditing(false);
        toast.success("Profile updated successfully!");
        setIsBookingsClicked(true);
    };

    const handleAllBookings = () => {
        dispatch(userBookingsAction({ endPoint: `users/userbookings?user_id=${userDetails.id}` }));
        setIsBookingsClicked(true);
        setIsEditing(false);
        setActiveTab('allBookings');
    };

    const handleLogout = () => {
        dispatch(logoutAction());
    };

    useEffect(() => {
        handleAllBookings();
    }, []);

    useEffect(() => {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        dispatch(getProfileDetailsAction({
            endPoint: `users/profile?user_id=${userDetails.id}`,
        }));
    }, [dispatch]);

    useEffect(() => {
        if (successData) {
            setProfile({
                name: successData.data.full_name,
                mobile: successData.data.mobile_no,
                email: successData.data.email,
            });
        }
    }, [successData]);

    useEffect(() => {
        if (updateSuccessData) {
            setProfile({
                name: updateSuccessData.data.full_name,
                mobile: updateSuccessData.data.mobile_no,
                email: updateSuccessData.data.email,
            });
        }
    }, [updateSuccessData]);

    useEffect(() => {
        if (profile) {
            setEditedName(profile.name);
            setEditedMobile(profile.mobile);
            setEditedEmail(profile.email);
        }
    }, [profile]);

    useEffect(() => {
        if (logoutSuccessData) {
            navigate("/");
            localStorage.clear();
            window.location.reload();
            toast.success("Logged out successfully");
        }
    }, [logoutSuccessData]);

    useEffect(() => {
        if (academyBookingsSuccessData) {
            console.log("academyBookingsSuccessData", academyBookingsSuccessData.data);
        }
    }, [academyBookingsSuccessData]);

    return (
        <Box>
            <Container className='maincontainer' sx={{ padding: { xs: '10px', sm: '20px' } }}>
                {isMobile && (
                    <>
                        <IconButton
                            color="black"
                            aria-label="open drawer"
                            edge="start"
                            onClick={toggleDrawer}
                            sx={{
                                position: 'fixed',
                                top: 10,
                                right: 0,
                                color: '#fff',
                                mr: 2,
                                display: { sm: 'none' },
                                zIndex: 1300,
                                p: 1,
                            }}
                        >
                            <Menu />
                        </IconButton>

                        <Drawer
                            anchor="left"
                            open={drawerOpen}
                            onClose={toggleDrawer}
                        >
                            <Box sx={{ width: 200, backgroundColor: '#30313b', height: '100%', color: '#fff',alignItems: 'center',justifyContent:'center',textAlign:'center' }}>
                                <Typography className="buttons" onClick={() => {
                                    handleAllBookings();
                                    toggleDrawer();
                                }} sx={{ cursor: 'pointer',padding:'2% 0%', display: 'flex', width:'100%' }}>
                                    <BookIcon /> All Bookings
                                </Typography>

                                <Typography className="buttons" onClick={() => {
                                    handleEditClick();
                                    toggleDrawer();
                                }} sx={{ cursor: 'pointer',padding:'2% 0%', display: 'flex',width:'100%' }}>
                                    <ModeEditOutlineIcon />  Edit Profile
                                </Typography>

                                <Typography className="buttons" onClick={() => {
                                    handleAcademyProfileClick();
                                    toggleDrawer();
                                }} sx={{ cursor: 'pointer',padding:'2% 0%', display: 'flex',width:'100%' }}>
                                     Academy Profiles
                                </Typography>

                                <Button
                                    onClick={() => {
                                        handleLogout();
                                        toggleDrawer();
                                    }}
                                    sx={{
                                        color: '#fff',
                                        width: '100%',
                                        padding:'8% 0%',
                                        alignItems:'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        },
                                    }} >
                                    <LogoutIcon />Logout
                                </Button>
                            </Box>
                        </Drawer>
                    </>
                )}
                <Box className='box' sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>

                    <Typography sx={{ color: '#000', marginBottom: { xs: '50px' }, paddingTop: '10%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                        <b>Welcome</b>
                        <p><hr style={{ width: '50%', color: '#000', margin: 'auto', border: '1px solid black', marginTop: '5px', marginBottom: '10px' }} /></p>
                        {profile.name}<br />
                        {profile.mobile}<br />
                        {profile.email}
                    </Typography>

                    {!isMobile && (
                        <Box className='box2'>
                            <Typography className={`${activeTab === 'allBookings' ? 'buttonsSelected' : 'buttons'}`} onClick={handleAllBookings} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <BookIcon /><p style={{ paddingLeft: '10px' }}>All Bookings</p>
                            </Typography>
                            <Typography className={`${activeTab === 'editProfile' ? 'buttonsSelected' : 'buttons'}`} onClick={handleEditClick} sx={{ marginTop: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <ModeEditOutlineIcon /><p style={{ paddingLeft: '10px' }}> Edit Profile</p>
                            </Typography>
                            <Typography className={`${activeTab === 'academyProfile' ? 'buttonsSelected' : 'buttons'}`} onClick={handleAcademyProfileClick} sx={{ marginTop: '5px', cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <p style={{ paddingLeft: '10px' }}>Academy Student Profiles</p>
                            </Typography>

                            <Box sx={{ marginTop: '20%', marginBottom: '20%', textAlign: 'center' }}>
                                <Button
                                    onClick={handleLogout}
                                    sx={{
                                        backgroundColor: '#30313b',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: '#000',
                                        },
                                    }}
                                    variant="contained"
                                    startIcon={<LogoutIcon />}
                                >
                                    Logout
                                </Button>
                            </Box>
                        </Box>
                    )}

                </Box>
                <Box sx={{
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', paddingTop: '5%', borderRadius: '8px', textAlign: 'center', width: { xs: '95%', sm: '60%' }, marginLeft: { xs: '2.5%', sm: '5%' }, marginTop: '2%', backgroundColor: '#fff', minHeight: { xs: '300px', sm: '400px' }
                }}>
                    {isEditing ? (
                        <Box>
                            <Typography sx={{ textAlign: 'center' }}><b>Edit Profile</b></Typography>
                            <TextField label="Name" value={editedName} onChange={(e) => setEditedName(e.target.value)} margin="normal" sx={{ width: '90%' }} />
                            <TextField label="Mobile" value={editedMobile} margin="normal" sx={{ width: '90%' }} disabled />
                            <TextField label="Email" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} margin="normal" sx={{ width: '90%' }} />
                            <Button onClick={handleSave} variant="contained" sx={{ color: "#fff", backgroundColor: "#31303b", width: "30%", margin: '5%' }}>Save</Button>
                        </Box>
                    ) :
                        isBookingsClicked ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ textAlign: 'center', fontSize: { xs: '1.5rem', sm: '2rem' }, mb: 2 }}>
                                    Booking Details
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                                    <Button variant="contained" sx={{ backgroundColor: '#31303b', fontSize: '0.75rem' }} onClick={handleSlotBookings}>Slot Bookings</Button>
                                    <Button variant="contained" sx={{ backgroundColor: '#31303b', fontSize: '0.75rem' }} onClick={handleAcademyBookings}>Academy Bookings</Button>
                                </Box>
                                {viewType !== 'main' && (
                                    <Box
                                        sx={{
                                            width: { xs: '100%', sm: '90%' },
                                            maxHeight: { xs: '30vh', sm: '50vh' },
                                            overflowY: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '8px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#31303b',
                                                borderRadius: '8px',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                background: '#f1f1f1',
                                                borderRadius: '8px',
                                            },
                                            mb: 2
                                        }}
                                    >
                                        <Typography variant="h5" sx={{ textAlign: 'center', fontSize: { xs: '1rem', sm: '1.5rem' }, mt: 2 }}>
                                            {viewType === 'slotBookings' ? 'Slot Bookings' : 'Academy Bookings'}
                                        </Typography>
                                        <hr style={{
                                            width: '50%',
                                            color: '#000',
                                            margin: 'auto',
                                            border: '1px solid black',
                                            marginTop: '5px',
                                            marginBottom: '10px',
                                        }} />
                                        {viewType === 'slotBookings' ? (
                                            bookingsSuccessData && bookingsSuccessData.data && bookingsSuccessData.data.filter(booking => booking.status === 'confirmed').length > 0 ? (
                                                bookingsSuccessData.data
                                                    .filter(booking => booking.status === 'confirmed')
                                                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                                    .map((booking, index) => (
                                                        <Card key={booking.booking_id} sx={{ marginBottom: '20px', width: { xs: '90%', sm: '70%' }, marginLeft: 'auto', marginRight: 'auto' }}>
                                                            <CardContent>
                                                                <Typography variant="h6" component="div" gutterBottom sx={{ textAlign: 'left', borderBottom: '2px solid' }}>
                                                                    Booking {index + 1}
                                                                </Typography>
                                                                <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                                                                    Booking Type: {booking.booking_type}
                                                                    <br />
                                                                    Booked For: {booking.booking_for}
                                                                    <br />
                                                                    Date: {booking.DailySlot.date}
                                                                    <br />
                                                                    Start Time: {booking.Slot.start_time}
                                                                    <br />
                                                                    Total Amount: ₹{parseInt(booking.DailySlot.surge_amount ? booking.DailySlot.surge_amount : 0) + parseInt(booking.Slot.base_price)}
                                                                    <br />
                                                                    Duration: {booking.Slot.duration / 60} Hour{booking.Slot.duration > 60 ? 's' : ''}
                                                                    <br />
                                                                    Status: {booking.status}
                                                                    <br />
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    ))
                                            ) : (
                                                <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                                                    You don't have any confirmed slot bookings yet.
                                                </Typography>
                                            )
                                        ) : (
                                            <>
                                                {viewType === 'academyBookings' ? (
                                                    academyBookingsSuccessData && academyBookingsSuccessData.data && academyBookingsSuccessData.data.filter(booking => booking.status === 'success').length > 0 ? (
                                                        academyBookingsSuccessData.data
                                                            .filter(booking => booking.status === 'success')
                                                            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                                            .map((booking, index) => (
                                                                <Card key={booking.booking_id} sx={{ marginBottom: '20px', width: { xs: '90%', sm: '70%' }, marginLeft: 'auto', marginRight: 'auto' }}>
                                                                    <CardContent>
                                                                        <Typography variant="h6" component="div" gutterBottom sx={{ textAlign: 'left', borderBottom: '2px solid' }}>
                                                                            Booking {index + 1}
                                                                        </Typography>
                                                                        <Typography variant="body1" sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                                                                            Student Name: {booking.Academy.student_name}
                                                                            <br />
                                                                            Age: {booking.Academy.student_age}
                                                                            <br />
                                                                            Gender: {booking.Academy.student_gender}
                                                                            <br />
                                                                            Father’s Name: {booking.Academy.father_name}
                                                                            <br />
                                                                            Father’s Mobile: {booking.Academy.father_mobile_no}
                                                                            <br />
                                                                            Sport: {booking.Academy.sport}
                                                                            <br />
                                                                            School: {booking.Academy.school_name}
                                                                            <br />
                                                                            Status: {booking.status}
                                                                            <br />
                                                                        </Typography>
                                                                    </CardContent>
                                                                </Card>
                                                            ))
                                                    ) : (
                                                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                                                            You don't have any confirmed academy bookings yet.
                                                        </Typography>
                                                    )
                                                ) : null}
                                            </>
                                        )}

                                    </Box>
                                )}
                            </Box>
                        ) : isAcademyProfileVisible ? (
                            <AcademyProfile />
                        ) : (
                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>
                                Select an option from the menu to view details.
                            </Typography>
                        )}
                </Box>
            </Container>
        </Box>
    );
}

export default Profile;