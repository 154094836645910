import sliceCreator from '../../index.js';
import { SUPERVISOR_SLOT_BOOKING } from '../../../constants/index.js';
const _supervisorSlotBookingAction = sliceCreator('SUPERVISOR_SLOT_BOOKING', SUPERVISOR_SLOT_BOOKING, 'post');

const { asyncAction: postSupervisorSlotBookingAction, reducer, clearData: clearPostSupervisorSlotBookingAction } = _supervisorSlotBookingAction;

export default reducer;

export {
    postSupervisorSlotBookingAction,
    clearPostSupervisorSlotBookingAction
}


