// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-title {
    font-size: 6rem;
    margin-bottom: 1rem;
  }
  
  .not-found-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }`, "",{"version":3,"sources":["webpack://./src/screens/NotFound/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB","sourcesContent":[".not-found-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    text-align: center;\n  }\n  \n  .not-found-title {\n    font-size: 6rem;\n    margin-bottom: 1rem;\n  }\n  \n  .not-found-subtitle {\n    font-size: 1.5rem;\n    margin-bottom: 2rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
