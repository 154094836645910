import sliceCreator from '../../index.js';
import { ACADEMY_SESSION_BOOKING } from '../../../constants/index.js';
const _academySessionBookingAction = sliceCreator('ACADEMY_SESSION_BOOKING', ACADEMY_SESSION_BOOKING, 'get');

const { asyncAction: academySessionBookingAction, reducer, clearData: clearAcademySessionBookingAction } = _academySessionBookingAction;

export default reducer;

export {
    academySessionBookingAction,
    clearAcademySessionBookingAction
}


