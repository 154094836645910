import sliceCreator from '../../index.js';
import {GETPROFILE} from '../../../constants/index.js';
const _profileDetailsAction = sliceCreator('GETPROFILE', GETPROFILE, 'get');

const { asyncAction: getProfileDetailsAction, reducer, clearData: clearProfileDetailsData } = _profileDetailsAction;

export default reducer;

export {
    getProfileDetailsAction,
    clearProfileDetailsData
}


