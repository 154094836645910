import sliceCreator from '../../index.js';
import {UPDATE_PROFILE} from '../../../constants/index.js';
const _updateProfileDetailsAction = sliceCreator('UPDATE_PROFILE', UPDATE_PROFILE , 'patch');

const { asyncAction: updateProfileDetailsAction, reducer, clearData: clearUpdateProfileDetailsAction } = _updateProfileDetailsAction;

export default reducer;

export {
     updateProfileDetailsAction,
     clearUpdateProfileDetailsAction
}


