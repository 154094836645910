import sliceCreator from '../../index.js';
import { ACADEMY_REGISTRATION } from '../../../constants/index.js';
const _academyRegistrationAction = sliceCreator('ACADEMY_REGISTRATION', ACADEMY_REGISTRATION, 'post');

const { asyncAction: academyRegistrationAction, reducer, clearData: clearAcademyRegistrationAction } = _academyRegistrationAction;

export default reducer;

export {
   academyRegistrationAction,
   clearAcademyRegistrationAction
}


