import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, Button, Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Facebook, WhatsApp, Email } from '@mui/icons-material';
import qr from '../../assets/qr.png';

function AcademyPayment() {
    const location = useLocation();
    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState(location.state?.cartItems || []);
    const [showPaymentConfirmation, setShowPaymentConfirmation] = useState(false);
    const [transactionId, setTransactionId] = useState('');
    const [showShareIcons, setShowShareIcons] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleTransactionSubmit = () => {
        setShowShareIcons(true);
        setShowSuccessModal(true);
    };

    // const handleShare = (platform) => {
    //     const bookingDetails = cartItems.map(item => `
    //   Sport: ${item.sport}
    //   Month: ${item.month}
    //   Session: ${item.session}
    //   Fee: ₹${item.fee}
    //   Name: ${item.name}
    //   Father Mobile: ${item.fatherMobile}
    //   Mother Mobile: ${item.motherMobile}
    // `).join('\n');

    //     const encodedDetails = encodeURIComponent(bookingDetails);
    //     let shareUrl = '';

    //     switch (platform) {
    //         case 'facebook':
    //             shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedDetails}`;
    //             break;
    //         case 'email':
    //             shareUrl = `mailto:?subject=Booking Details&body=${encodedDetails}`;
    //             break;
    //         case 'whatsapp':
    //             shareUrl = `https://api.whatsapp.com/send?text=${encodedDetails}`;
    //             break;
    //         default:
    //             return;
    //     }

    //     window.open(shareUrl, '_blank');
    // };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        navigate('/');
    };

    const buttonStyle = {
        backgroundColor: '#31303b',
        color: '#fff',
        cursor: 'pointer',
        marginTop: '20px',
        borderRadius: '15px',
        width: '30%',
        '&:hover': {
          backgroundColor: '#000',
          color: '#fff',
        },
      };

    return (
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '2rem', marginTop: '5rem', marginBottom: '40px', padding: '0 1rem', alignItems: 'flex-start', justifyContent: 'center' }}>
      <Box sx={{ flex: 1, width: '100%', maxWidth: { xs: '100%', md: '400px' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    
          <Paper elevation={3} sx={{ padding: '1rem', borderRadius: '15px', width: '90%', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>Payment Confirmation</Typography>
            <hr style={{ margin: '10px 0', width: '70%', marginLeft: '15%', border: '1px solid black' }} />
            <img src={qr} alt="QR Code" style={{ width: '100%', maxWidth: '250px', height: 'auto' }} />
            <Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography component="label" sx={{ marginBottom: '5px' }}>Transaction ID:</Typography>
              <input
                type="text"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                placeholder="Enter transaction ID"
                maxLength={12}
                style={{ width: '100%', maxWidth: '300px', padding: '8px' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleTransactionSubmit}
                sx={{ ...buttonStyle, marginTop: '10px' }}
                disabled={transactionId.length < 12}
              >
                Submit
              </Button>
            </Box>
          </Paper>
      </Box>

                
                        
            <Dialog open={showSuccessModal} onClose={handleCloseModal}>
                <DialogTitle>Payment Successful</DialogTitle>
                <DialogContent>
                    <Typography>Your payment was successful!</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default AcademyPayment;
