import sliceCreator from '../../index.js';
import { SUPERVISOR_CREATE_USER } from '../../../constants/index.js';
const _supervisorCreateUserAction = sliceCreator('SUPERVISOR_CREATE_USER', SUPERVISOR_CREATE_USER, 'post');

const { asyncAction: supervisorCreateUserAction, reducer, clearData: clearSupervisorCreateUserAction } = _supervisorCreateUserAction;

export default reducer;

export {
    supervisorCreateUserAction,
    clearSupervisorCreateUserAction
}


