import * as React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Drawer, Box, IconButton } from '@mui/material';
import { Dashboard, EventNote, People, Menu } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Payment from '@mui/icons-material/Payment';
import { SportsCricket, AddCircle } from '@mui/icons-material';

const drawerWidth = 230;

export default function Sidebar() {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleListItemClick = (path) => {
    navigate(path);
    setMobileOpen(false); // Close the drawer on mobile
  };

  const drawer = (
    <List>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/dashboard')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><Dashboard sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/currentbookings')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><EventNote sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Current Bookings" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/supervisors')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><People sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Supervisor Details" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/admin-payment')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><Payment sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Payment Details" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/bonus-slot')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><AddCircle sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="User Details" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/slots')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><SportsCricket sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Slots" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/academy-sessions')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><EventNote sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Academy Sessions" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/admin/academy-adminssions')}
        sx={{ height: '70px', '&:hover': { backgroundColor: 'gray' } }}
      >
        <ListItemIcon><EventNote sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Academy Adminssions" />
      </ListItem>
      <ListItem
        button
        onClick={() => handleListItemClick('/supervisor')}
        sx={{
          height: '70px',
          '&:hover': { backgroundColor: 'gray' },
          display: { xs: 'flex', sm: 'none' }
        }}
      >
        <ListItemIcon><People sx={{ color: '#fff' }} /></ListItemIcon>
        <ListItemText primary="Supervisors" />
      </ListItem>
    </List>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        color="black"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          position: 'fixed',
          top: 15,
          right: 0,
          color: '#fff',
          mr: 2,
          display: { sm: 'none' },
          zIndex: 1300,
          p: 1,
        }}
      >
        <Menu />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#31303b',
            color: '#fff',
            height: '100vh', // Ensure the drawer takes the full viewport height
            overflowY: 'auto', // Enable vertical scrolling
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            marginTop: '80px',
            backgroundColor: '#31303b',
            color: '#fff',
            height: 'calc(100vh - 64px)', // Set height minus top margin
            overflowY: 'auto', // Enable vertical scrolling
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
