import sliceCreator from '../../index.js';
import {VERIFY_OTP} from '../../../constants/index.js';
const _verifyOtpAction = sliceCreator('VERIFY_OTP', VERIFY_OTP, 'post');

const { asyncAction: verifyOtpAction, reducer, clearData: clearVerifyOtpData } = _verifyOtpAction;

export default reducer;

export {
    verifyOtpAction,
    clearVerifyOtpData
}


