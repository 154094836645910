
import { Outlet, Link } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Layout = () => {
  return (
    <div>
      <Navbar/>
      <main>
        <Outlet />
      </main>
      <Footer/>      
    </div>
  );
};

export default Layout;
