import { Typography } from '@mui/material'
import React from 'react'
import "./index.css"
import Footerterms from './terms.js'
import { useLocation } from 'react-router-dom';

function Footer() {
  const location = useLocation(); 
  const noFooterRoutes = ['/terms','/privacy','/contactus','/refund','/shipping']; 
  const shouldDisplayFooter = !noFooterRoutes.includes(location.pathname);

  return (
    <div>
       {shouldDisplayFooter && <Footerterms />}
      <footer className="footer">

        <Typography
          variant="h6"
          component="div"
          sx={{
            fontSize: { xs: '8px', sm: '18px' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            padding: '7px',
          }}
        >
          <p style={{ margin: "0px", textAlign: 'left', }}>Copyright © 2024 @ Super T20 Cricket Academy & Box Cricket - All rights reserved</p>
          <p style={{ paddingRight: "2%", margin: "0px", textAlign: 'end' }}>- A  product By Clearbiz Software Solutions</p>
        </Typography>
      </footer>
    </div>
  )
}
export default Footer;
