import sliceCreator from '../../index.js';
import { ACADEMY_SESSIONS } from '../../../constants/index.js';
const _academySessionAction = sliceCreator('ACADEMY_SESSIONS', ACADEMY_SESSIONS, 'post');

const { asyncAction: postAcademySessionsAction, reducer, clearData: clearPostAcademySessionsAction } = _academySessionAction;

export default reducer;

export {
    postAcademySessionsAction,
    clearPostAcademySessionsAction
}


