import sliceCreator from '../../index.js';
import { UPDATEUSER } from '../../../constants/index.js';
const _updateUserAction = sliceCreator('UPDATEUSER', UPDATEUSER, 'patch');

const { asyncAction: getUpdateUserAction, reducer, clearData: clearGetUpdateUserAction } = _updateUserAction;

export default reducer;

export {
    getUpdateUserAction,
    clearGetUpdateUserAction
}